import React from "react";
import { ExclamationCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { BeaconActiveError, ErrorSeverity } from "../api";
import { Tooltip } from "antd";
import { BeaconActiveErrorsTooltip } from "./BeaconActiveErrorsTooltip";

interface BeaconErrorStatusProps {
    errors?: BeaconActiveError[];
    severity?: ErrorSeverity;
    style?: React.CSSProperties;
    showTooltip?: boolean;
}

export function BeaconErrorStatus(props: BeaconErrorStatusProps) {

    const isError = props.severity === ErrorSeverity.Error ||
        (props.errors && props.errors.some(e => e.severity === ErrorSeverity.Error));

    const showTooltip = props.showTooltip && props.errors?.length;

    const style = {
        ...(props.style ?? {}),
        color: isError ? 'red' : 'orange'
    };

    const icon = isError
        ? <ExclamationCircleOutlined style={style} />
        : <WarningOutlined style={style} />;

    return showTooltip
        ? <Tooltip style={{ width: 'auto' }} title={<BeaconActiveErrorsTooltip errors={props.errors!} />} overlayStyle={{ width: 'auto', maxWidth: 800 }}>
            {icon}
        </Tooltip>
        : icon;
}