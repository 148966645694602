import { useCallback, useContext, useEffect, useState } from 'react';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { useTranslation, TFunction } from 'react-i18next';
import { AuthContext } from '../contexts/authContext';
import { Button, Dropdown, Menu, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { DataAutoRefreshContext } from '../contexts/dataRefreshContext';
import { getGlobalShowCanvasAllBeacons, setGlobalShowCanvasAllBeacons } from './globalState';

interface CommonUserMenuProps {
  i18n: any;
  ready: boolean;
  t: TFunction;
  interval?: number;
  setInterval?: (value: number) => void;
  includeReloadRate: boolean;
  german: () => void;
  english: () => void;
  showCanvasAllBeacons: boolean;  
  toggleCanvas: (checked: boolean) => void; 
}

// Function to render toggle buttons
const renderToggleButton = (label: string, isActive: boolean, onClick: () => void) => {
  return (
      <Button 
          onClick={onClick} 
          type={isActive ? 'primary' : 'default'}>
          {label}
      </Button>
  );
};

export const getMenuContent = ({ i18n, ready, t, interval, setInterval, includeReloadRate, german, english, showCanvasAllBeacons, toggleCanvas }: CommonUserMenuProps) => {
  return (<Menu>
    {ready && (<div className="multi-button-container" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '5px 12px' }}>
      <Button onClick={german} type={i18n.language === 'de' || i18n.language?.startsWith('de-')  ? 'primary' : 'default'}>Deutsch</Button>
      <Button onClick={english} type={i18n.language === 'en' || i18n.language?.startsWith('en-')  ? 'primary' : 'default'}>English</Button>
    </div>)}
    <Menu.Divider />
    <div style={{ padding: '5px 12px', width: '100%' }}>
      <div>{t('list-over-pagination')}</div>
      {ready && (<div className="multi-button-container" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', padding: '5px 12px', marginTop: '10px' }}>
        {renderToggleButton('Yes', !showCanvasAllBeacons, () => {
          setGlobalShowCanvasAllBeacons(false);
          toggleCanvas(false);
        })}
        {renderToggleButton('No', showCanvasAllBeacons, () => {
          setGlobalShowCanvasAllBeacons(true);
          toggleCanvas(true);
        })}
        </div>
      )}
    </div>
    {includeReloadRate && interval !== undefined && setInterval && (<>
    <Menu.Divider />
    <div style={{ padding: '5px 12px', width: '100%' }} data-testid="UserMenuDropdown">
      <div>{t('reload-rate')}</div>
      <Select value={interval} onChange={setInterval} style={{ width: '100%' }}>
        <Select.Option value={1000}>{t('reload-rate-1000')}</Select.Option>
        <Select.Option value={10000}>{t('reload-rate-10000')}</Select.Option>
        <Select.Option value={30000}>{t('reload-rate-30000')}</Select.Option>
        <Select.Option value={60000}>{t('reload-rate-60000')}</Select.Option>
        <Select.Option value={3600000}>{t('reload-rate-3600000')}</Select.Option>
      </Select>
    </div></>)}
    <Menu.Divider />
    <Menu.Item>
      <AmplifySignOut />
    </Menu.Item>
  </Menu>
  );
};

export function UserMenuNoOrganizationsAttached(props: CommonUserMenuProps) {
  const authContext = useContext(AuthContext);
  const { i18n, ready, t } = useTranslation();
  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);

  if (!authContext.currentUser) {
    return <>User not found. Please contact administrator!</>;
  }

  const menu = getMenuContent({i18n, ready, t, includeReloadRate: false, german, english, showCanvasAllBeacons: props.showCanvasAllBeacons, toggleCanvas: props.toggleCanvas });

  return (
    <Dropdown overlay={menu}>
      <a href="/" className="ant3600000-dropdown-link" onClick={e => e.preventDefault()} style={{ color: 'white' }}>
        {authContext.currentUser.email} <DownOutlined />
      </a>
    </Dropdown>
  );
}

export function UserMenu(props: CommonUserMenuProps) {
  const authContext = useContext(AuthContext);
  const { interval, setInterval } = useContext(DataAutoRefreshContext);
  const { i18n, ready, t } = useTranslation();
  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);

  const userId = authContext.currentUser?.email || 'defaultUser';
  const [showCanvasAllBeacons, setShowCanvasAllBeacons] = useState<boolean>(getGlobalShowCanvasAllBeacons());

  // Loading the user's preference from localStorage when a user logs in
  useEffect(() => {
    const savedPreference = localStorage.getItem(`${userId}_showCanvasAllBeacons`);
      if (savedPreference !== null) {
        setShowCanvasAllBeacons(JSON.parse(savedPreference));
        setGlobalShowCanvasAllBeacons(JSON.parse(savedPreference));  // Syncing global state
      }
  }, [userId]);

  const handleToggleCanvasView = (value: boolean) => {
    setShowCanvasAllBeacons(value); 
    setGlobalShowCanvasAllBeacons(value); 
    localStorage.setItem(`${userId}_showCanvasAllBeacons`, JSON.stringify(value)); 
    props.toggleCanvas(value);
  };

  if (!authContext.currentUser) {
    return <>User not found. Please contact administrator</>;
  }

  const menu = getMenuContent({i18n, ready, t, interval, setInterval, includeReloadRate: true, german, english, 
    showCanvasAllBeacons, // Using the locally managed state
    toggleCanvas: handleToggleCanvasView, // Using the updated handler that syncs everything
  });

  return (
    <Dropdown overlay={menu}>
      <a href="/" className="ant3600000-dropdown-link" onClick={e => e.preventDefault()} style={{ color: 'white' }}>
        {authContext.currentUser.email} <DownOutlined />
      </a>
    </Dropdown>
  );
}