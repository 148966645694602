let globalShowCanvasAllBeacons = true;
let listeners = [];

export const setGlobalShowCanvasAllBeacons = (value) => {
    globalShowCanvasAllBeacons = value;
    listeners.forEach(listener => listener(value));  // Notifying all listeners
};

export const getGlobalShowCanvasAllBeacons = () => {
    return globalShowCanvasAllBeacons;
};

// This function allows components to subscribe to changes in the global state
export const subscribeToGlobalShowCanvasAllBeacons = (callback) => {
    listeners.push(callback);
    return () => {
        listeners = listeners.filter(listener => listener !== callback);
    };
};