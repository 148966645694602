import { MapContainer, TileLayer, Marker, Tooltip, Polyline, Polygon, Circle, useMap } from 'react-leaflet';
import { latLngBounds, Icon, LatLngBounds, Map as LeafletMap, LatLng } from 'leaflet';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useBeaconWithPosition, LinearRing, useBeacon } from '../api';
import { useDimension } from '../util/useDimension';
import { useParams } from 'react-router-dom';

declare module 'leaflet' {
    interface Map {
        resetUserSelection(): void;
    }
}

export const defaultMarkerIcon = new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-blue.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

export const greenMarkerIcon = new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-green.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


export const greyMarkerIcon = new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-grey.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


export const redMarkerIcon = new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-red.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});


export const orangeMarkerIcon = new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-orange.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41]
});

const beaconStateIcon = (state: string) => {
    return new Icon({
        iconUrl: '/assets/beacon-state-' + state + '.png',
        iconSize: [16, 16],
        iconAnchor: [8, 60],
        popupAnchor: [1, -34],
    });
};

const beaconOkIcon = beaconStateIcon('ok');
const beaconErrorIcon = beaconStateIcon('error');
const beaconWarningIcon = beaconStateIcon('warning');

export type MarkerColor = 'blue' | 'green' | 'grey' | 'red' | 'orange';

const icons = {
    blue: defaultMarkerIcon,
    green: greenMarkerIcon,
    grey: greyMarkerIcon,
    red: redMarkerIcon,
    orange: orangeMarkerIcon
}

export interface Position {
    lat: number;
    lon: number;
}

export interface CirclePosition extends Position {
    radiusInMeters: number;
    title?: string;
    color?: React.CSSProperties['color'];
    onClick?: () => void;
}

export interface MarkerPosition extends Position {
    title: string;
    color?: MarkerColor;
    hasError?: boolean;
    errorText?: React.ReactNode;
    errorSeverity?: 'error' | 'warning';
    onClick?: () => void;
}

export interface MapPolygon {
    title?: string;
    ring: LinearRing;
    color?: React.CSSProperties['color'];
    onClick?: () => void;
}

export interface MapPolyline {
    line: Position[];
    title?: string;
    color?: React.CSSProperties['color'];
    width?: number;
    onClick?: () => void;
}

interface MapProps {
    markers: MarkerPosition[];
    circle?: CirclePosition;
    circles?: CirclePosition[];
    allowNoMarkers?: boolean;
    polyLines?: MapPolyline[];
    polygons?: MapPolygon[];
    controls?: React.ReactNode;
    onMapReady?: (map: LeafletMap) => void;
    defaultPosition?: MapPosition;
    onUserDraggedOrZoomed?: (zoom: number, bounds: LatLngBounds) => void;
}

export interface MapPosition {
    bounds: LatLngBounds | undefined;
    zoom: number | undefined;
    center: { lat: number; lng: number; } | undefined;
}

function DefaultMapPositionSetter(props: MapPosition) {
    const map = useMap();
    const { bounds, zoom, center } = props;
    const [lastBounds, setLastBounds] = useState<LatLngBounds | undefined>();
    const [lastZoom, setLastZoom] = useState<number | undefined>();
    const [lastCenter, setLastCenter] = useState<{ lat: number; lng: number; } | undefined>();

    useEffect(() => {
        if (!map) {
            return;
        }

        if (bounds) {
            if (lastBounds && lastBounds.equals(bounds)) {
                return;
            }
            map.fitBounds(bounds);
            setLastBounds(bounds);
            return;
        }

        if (center && zoom) {
            if (lastCenter?.lat === center.lat && lastCenter.lng === center.lng && lastZoom === zoom) {
                return;
            }
            map.setView(center, zoom);
            setLastCenter(center);
            setLastZoom(zoom);
            return;
        }
    }, [map, bounds, zoom, center, lastBounds, lastZoom, lastCenter]);
    return <></>;
}

export function getDefaultMapPosition(markers?: MarkerPosition[], polygons?: MapPolygon[]): MapPosition {
    let bounds: LatLngBounds | undefined = undefined;
    let zoom: number | undefined = 19;
    let center: { lat: number, lng: number } | undefined = undefined;

    if (!markers?.length && !polygons?.length) {
        // Zoom to all of germany
        bounds = undefined;
        zoom = 6;
        center = { lat: 51.37855903090376, lng: 10.052490234375002 };
    }
    else if (markers?.length === 1 && !polygons?.length) {
        bounds = undefined;
        zoom = 19;
        center = { lat: markers[0].lat, lng: markers[0].lon };
    } else {
        const markerPositions = (markers || []).map(m => ({ lat: m.lat, lng: m.lon }));
        const polygonPositions = polygons ? polygons.flatMap(polygon => polygon.ring.map(point => ({ lat: point.latitude, lng: point.longitude }))) : [];
        bounds = latLngBounds([...markerPositions, ...polygonPositions]);
        zoom = undefined;
        center = undefined;
    }
    return { bounds, zoom, center };
}

export function Map(props: MapProps) {
    const { t } = useTranslation();
    const noop = useCallback(() => { }, []);
    const [userCenter, setUserCenter] = useState<LatLng>();
    const [userZoom, setUserZoom] = useState<number>();
    const { onMapReady, onUserDraggedOrZoomed } = props;
    const { beaconId } = useParams<{ beaconId: string }>();
    const beacon = useBeacon(beaconId);
    const beaconPosition = useBeaconWithPosition(beaconId);

    type BeaconMarkerColor = 'blue' | 'green' | 'grey' | 'red' | 'orange';

    function getMarkerColor(): BeaconMarkerColor {
        const onOffSwitch = beacon.value?.onOffSwitch?.isOn;
        let markerColor: BeaconMarkerColor = 'blue'; // Keeping the blue icon as default. It is used as a default in case one of the four conditions for setting the icon is not met.

        if (onOffSwitch === false) {
            markerColor = 'grey'; // Beacon is off
        } else {
            if (beacon.value?.activeErrors && beacon.value.activeErrors.length > 0) {
                markerColor = 'red'; // Active error and beacon is on
            } else if (beaconPosition.value?.position.value.md.cprs === 2) {
                markerColor = 'green'; // CPRS 2 (RTK-Fixed) and beacon is on
            } else if (beaconPosition.value?.position.value.md.cprs !== undefined && [0, 1].includes(beaconPosition.value?.position.value.md.cprs)) {
                markerColor = 'orange'; // Other CPRS value and beacon is on
            } else {
                markerColor = 'blue';
            }
        }
        return markerColor; 
    }

    const markerColor: MarkerColor = getMarkerColor();

    const onDraggedOrZoomed = useCallback((map: LeafletMap) => {
        setUserCenter(map.getCenter());
        setUserZoom(map.getZoom());
        if (onUserDraggedOrZoomed) {
            onUserDraggedOrZoomed(map.getZoom(), map.getBounds());
        }
    }, [setUserCenter, setUserZoom, onUserDraggedOrZoomed]);

    const containerRef = useRef<HTMLDivElement>();
    const dimension = useDimension(containerRef as any);
    const setDimensionHandler = dimension.setHandler;

    const whenCreated = useCallback((map: LeafletMap) => {

        map.removeEventListener('dragend');
        map.on('dragend', () => onDraggedOrZoomed(map));

        map.removeEventListener('zoomend');
        map.on('zoomend', () => onDraggedOrZoomed(map));

        map.resetUserSelection = () => {
            setUserZoom(undefined);
            setUserCenter(undefined);
        }

        if (onMapReady) {
            onMapReady(map);
        }

        setDimensionHandler(() => {
            try {
                map.invalidateSize();
            }
            catch { }
        });
    }, [onMapReady, onDraggedOrZoomed, setDimensionHandler, setUserCenter, setUserZoom]);

    if (props.markers.length === 0 && !props.allowNoMarkers) {
        return <Alert message={t('no-data')} type="info" showIcon style={{ alignSelf: 'flex-start' }} />
    }

    const defaultPosition = props.defaultPosition || getDefaultMapPosition(props.markers, props.polygons);

    const effectiveZoom = userZoom || defaultPosition.zoom;
    const effectiveCenter = userCenter || defaultPosition.center;
    const hasZoomAndCenter = !!effectiveZoom && !!effectiveCenter;

    return <div style={{ height: '100%', width: '100%' }} ref={containerRef as any}>
        <MapContainer
            style={{ height: '100%', width: '100%' }}
            center={hasZoomAndCenter ? effectiveCenter : undefined}
            zoom={hasZoomAndCenter ? effectiveZoom : undefined}
            bounds={hasZoomAndCenter ? undefined : defaultPosition.bounds}
            maxZoom={25}
            whenCreated={whenCreated}>
            {props.controls}
            <DefaultMapPositionSetter bounds={defaultPosition.bounds} center={defaultPosition.center} zoom={defaultPosition.zoom} />
            <TileLayer maxZoom={25} maxNativeZoom={19}
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

            {props.markers.map((m, i) => <>
                <Marker key={"marker_" + i} icon={icons[m.color || markerColor]} position={[m.lat, m.lon]} eventHandlers={{ click: m.onClick || noop }}>
                    <Tooltip offset={[10, -28]}>{m.title}</Tooltip>
                </Marker>
                {m.hasError !== undefined && <Marker key={"state_marker_" + i} icon={m.hasError ? (m.errorSeverity === 'warning' ? beaconWarningIcon : beaconErrorIcon) : beaconOkIcon} position={[m.lat, m.lon]}>
                    {m.errorText && <Tooltip offset={[10, -52]} >{m.errorText}</Tooltip>}
                </Marker>}
            </>)}

            {props.polyLines && props.polyLines.map((polyline, i) => <Polyline key={'polyline_' + i} color={polyline.color || '#1890ff'} weight={polyline.width || 3} positions={polyline.line.map(point => ([point.lat, point.lon]))} eventHandlers={{ click: polyline.onClick || noop }}>{polyline.title && <Tooltip>{polyline.title}</Tooltip>}</Polyline>)}
            {props.polygons && props.polygons.map((polygon, i) => <Polygon key={'polygon_' + i} color={polygon.color || '#1890ff'} positions={polygon.ring.map(p => ([p.latitude, p.longitude]))} eventHandlers={{ click: polygon.onClick || noop }}>
                {polygon.title && <Tooltip>{polygon.title}</Tooltip>}
            </Polygon>)}
            {props.circle && <Circle center={[props.circle.lat, props.circle.lon]} radius={props.circle.radiusInMeters} />}
            {props.circles?.length && props.circles.map((c, i) => <Circle key={'circle_' + i} color={c.color || '#1890ff'} center={[c.lat, c.lon]} radius={c.radiusInMeters} eventHandlers={{ click: c.onClick || noop }}>{c.title && <Tooltip>{c.title}</Tooltip>}</Circle>)}
        </MapContainer>
    </div>;
}