export function minBy<T>(items: T[], pluck: (i: T) => number | undefined | null) {
    const values = items.map(i => ({ item: i, value: pluck(i) })).filter(i => i.value !== undefined && i.value !== null);
    if (values.length === 0) {
        return undefined;
    }

    let min = values[0];

    for (let i = 1; i < items.length; i++) {
        const v = values[i];
        if (v.value! < min.value!) {
            min = v;
        }
    }

    return min;
};

export function maxBy<T>(items: T[], pluck: (i: T) => number | undefined | null) {

    const values = items.map(i => ({ item: i, value: pluck(i) })).filter(i => i.value !== undefined && i.value !== null);
    if (values.length === 0) {
        return undefined;
    }

    let max = values[0];


    for (let i = 1; i < items.length; i++) {
        const v = values[i]
        if (v.value! > max.value!) {
            max = v;
        }
    }

    return max;
};

export function averageBy<T>(items: T[], pluck: (i: T) => number | undefined | null) {

    const values = items.map(i => ({ item: i, value: pluck(i) })).filter(i => i.value !== undefined && i.value !== null);
    if (values.length === 0) {
        return undefined;
    }

    let average = values[0].value || 0;

    for (let i = 1; i < items.length; i++) {
        const v = values[i]
        if (v.value) {
            average += v.value;
        }
    }

    return average / items.length;
};



export function count<T>(items: T[], filter: (i: T) => boolean) {
    let c = 0;
    items.forEach(i => {
        if (filter(i)) {
            c++;
        }
    });
    return c;
};