import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from '@ant-design/icons';
import { BeaconWithPosition, useAssignBeaconsToConstructionProject } from "../../api";
import { SelectBeaconsModal } from "../../components/SelectBeaconsModal";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { MapPosition } from "../../components/Map";

interface AssignBeaconsModalProps {
    modalButton?: (openModal: () => void) => React.ReactNode;
    constructionProjectId: string;
    beacons?: BeaconWithPosition[];
    title?: string;
    defaultMapPosition?: MapPosition;
}

export function AssignBeaconsModal(props: AssignBeaconsModalProps) {
    const { constructionProjectId } = props;
    const { t } = useTranslation();
    const assignBeaconsToConstructionProject = useAssignBeaconsToConstructionProject();
    const dataRefreshContext = useContext(DataRefreshContext);

    const onAssignBeaconsToConstructionProject = async (beaconIds: string[]) => {
        await assignBeaconsToConstructionProject({ beaconIds, constructionProjectId });
        dataRefreshContext.refresh();
    }

    return <SelectBeaconsModal
        title={props.title ?? t('construction-project:assign-beacons')}
        instruction={t('construction-project:assign-beacons-drawing-tooltip')}
        beacons={props.beacons}
        defaultMapPosition={props.defaultMapPosition}
        selectButtonText={beaconIds => t('construction-project:assign-beacons', { count: beaconIds.length })}
        selectButtonTitle={beaconIds => t('construction-project:assign-beacons-message', { count: beaconIds.length })}
        modalButton={props.modalButton}
        modalButtonIcon={props.modalButton ? undefined : <PlusCircleOutlined />}
        onBeaconsSelected={onAssignBeaconsToConstructionProject} />
}