import { useCallback, useContext, useState } from "react";
import { ConstructionSite, LinearRing, useAssignConstructionSite, useCreateConstructionSite, useGetBeaconsInArea } from "../../api";
import { Map as LeafletMap } from 'leaflet';
import { averageBy } from "../../util";
import { DataAutoRefreshContext } from "../../contexts/dataRefreshContext";

function tryClickWithoutRetry(selector: string, map?: LeafletMap, filter?: (e: Element) => boolean) {
    if (!map) {
        return false;
    }
    try {
        const element = map.getContainer().querySelector(selector) as any;
        if (element?.click && (!filter || filter(element))) {
            element.click();
            return true;
        }
    } catch { }
    return false;
}

function tryClick(selector: string, retries: number, map?: LeafletMap) {
    if (!map) {
        return;
    }
    setTimeout(() => {
        if (tryClickWithoutRetry(selector, map)) {
            return;
        }

        if (retries > 0) {
            tryClick(selector, retries - 1, map);
        }
    }, 50);
}


function naiveExpand(area: LinearRing) {
    const centerLat = averageBy(area, p => p.latitude)!;
    const centerLon = averageBy(area, p => p.longitude)!;

    return area.map(p => {
        const vector = { lat: p.latitude - centerLat, lon: p.longitude - centerLon };
        const length = Math.sqrt(vector.lat * vector.lat + vector.lon * vector.lon);
        return {
            latitude: p.latitude + vector.lat * 0.00002 / length,
            longitude: p.longitude + vector.lon * 0.00002 / length
        };
    });
}

function expand(area: LinearRing) {
    try {
        return naiveExpand(area);
    } catch { }
    return area;
}

export function useConstructionSiteEditMode(constructionProjectId: string, reload: () => Promise<void>, map?: LeafletMap) {
    const [isOpen, setIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [constructionSite, setConstructionSite] = useState<ConstructionSite>();
    const [area, setArea] = useState<LinearRing>();
    const createConstructionSite = useCreateConstructionSite(constructionProjectId);
    const editConstructionSite = useAssignConstructionSite(constructionProjectId);
    const getBeaconsInArea = useGetBeaconsInArea();
    const { enable: enableAutoRefresh, disable: disableAutoRefresh } = useContext(DataAutoRefreshContext);
    
    const onAreaSet = useCallback((a?: LinearRing) => {
        setArea(a);
        (map as any).LATEST_AREA = a;
    }, [setArea, map]);

    const create = useCallback(() => {
        disableAutoRefresh();
        setIsOpen(true);
        setConstructionSite(undefined);
        setArea(undefined);
        tryClick('.leaflet-draw-draw-polygon', 10, map);
    }, [setIsOpen, setConstructionSite, setArea, map, disableAutoRefresh]);

    const edit = useCallback((constructionSite: ConstructionSite) => {
        disableAutoRefresh();
        setIsOpen(true);
        setConstructionSite(constructionSite);
        (map as any).LATEST_AREA = undefined;

        let area: LinearRing | undefined;
        if (constructionSite.polygon) {
            area = expand(constructionSite.polygon);
            area.push(area[0]);
        }
        setArea(area);
        if (area) {
            tryClick('.leaflet-draw-edit-edit', 10, map);
        } else {
            tryClick('.leaflet-draw-draw-polygon', 10, map);
        }

    }, [setIsOpen, setConstructionSite, setArea, map, disableAutoRefresh]);

    const cancel = useCallback(() => {
        enableAutoRefresh();
        setIsOpen(false);
        setConstructionSite(undefined);
        setArea(undefined);
        (map as any).LATEST_AREA = undefined;
    }, [setIsOpen, setConstructionSite, setArea, map, enableAutoRefresh]);

    const save = useCallback(async () => {
        let areaToSave = area;
        if (tryClickWithoutRetry('.leaflet-draw-actions li a', map, e => e.textContent === 'Save')) {
            const latestArea = (map as any)?.LATEST_AREA as LinearRing;
            if (latestArea) {
                areaToSave = latestArea;
            }
            (map as any).LATEST_AREA = undefined;
        };

        if (areaToSave && !constructionSite) {
            setIsSaving(true);
            try {
                // Create...
                var beaconsInArea = await getBeaconsInArea({ area: areaToSave });
                var beaconsToAssign = beaconsInArea!.filter(b => b.constructionProjectId === constructionProjectId);
                await createConstructionSite({ beaconIds: beaconsToAssign.map(b => b.id) });
                await reload();
            }
            finally {
                setIsSaving(false);
            }
        } else if (areaToSave && constructionSite) {
            // Edit...
            setIsSaving(true);
            try {
                var beaconsInArea = await getBeaconsInArea({ area: areaToSave });
                var beaconsToAssign = beaconsInArea!.filter(b => b.constructionProjectId === constructionProjectId);
                await editConstructionSite({ beaconIds: beaconsToAssign.map(b => b.id), exclusive: true, constructionSiteId: constructionSite.id });
                await reload();
            }
            finally {
                setIsSaving(false);
            }
        }

        enableAutoRefresh();
        setIsOpen(false);
        setConstructionSite(undefined);
        setArea(undefined);
    }, [setIsOpen, setIsSaving, constructionSite, setConstructionSite, setArea, getBeaconsInArea, constructionProjectId, createConstructionSite, editConstructionSite, reload, map, enableAutoRefresh]);

    return { create, edit, cancel, save, area, setArea: onAreaSet, isOpen, isSaving, editedConstructionSite: constructionSite };
}