import { FunctionComponent, useCallback, useContext, useState } from 'react';
import './App.css';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import Auth from '@aws-amplify/auth';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { routes } from './routes';
import { NavigationMenu } from './components/NavigationMenu';
import { AuthContext, CurrentUser, AccessToken } from './contexts/authContext';
import { DataAutoRefreshContext, DataRefreshContext } from './contexts/dataRefreshContext';
import { UserMenu, UserMenuNoOrganizationsAttached } from './components/UserMenu';
import { Environment } from './components/Environment';
import { ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useSetting } from './util';
import { useMyOrganization } from './api';
import { NoOrganization } from './screens/no-role/NoRole';
import { BeaconDrawerContextProvider } from './components/beacon-drawer/BeaconDrawerContext';

function VerifyRoadSafetyManagerHasAnOrganization(props: { children: React.ReactNode, showCanvasAllBeacons: boolean, toggleCanvas: (checked: boolean) => void }) {
  const authContext = useContext(AuthContext);
  const isRoadSafetyManager = authContext.currentUser?.accessToken?.hasRole('road-safety-manager');
  const myOrg = useMyOrganization();
  const { i18n, ready, t } = useTranslation();
  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);

  return <>{isRoadSafetyManager && !myOrg.loading && !myOrg.value?.id ? (
  <BrowserRouter><BeaconDrawerContextProvider>
    <Layout style={{ height: "100vh" }}>
      <Layout.Header>
        <div className="app-cm1-logo"></div>
        <div style={{ float: 'right', marginRight: -20, userSelect: 'none' }}>
          <UserMenu
            i18n={i18n}
            ready={ready}
            t={t}
            includeReloadRate={true} 
            german={german}
            english={english}
            showCanvasAllBeacons={props.showCanvasAllBeacons}
            toggleCanvas={props.toggleCanvas}
          />
        </div>
      </Layout.Header>
    </Layout>
  </BeaconDrawerContextProvider>
  <NoOrganization />
  </BrowserRouter>) : props.children}</>;
}

interface AppLayoutProps {
  user: CurrentUser;
  onRefreshClick: () => void;
  redirectPath: string;
  showCanvasAllBeacons: boolean;  
  toggleCanvas: (checked: boolean) => void;  
}

const AppLayout: FunctionComponent<AppLayoutProps> = ({ user, onRefreshClick, redirectPath, showCanvasAllBeacons, toggleCanvas }) => {
  const { i18n, ready, t } = useTranslation();
  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);
  
  return (
    <BrowserRouter>
      <BeaconDrawerContextProvider>
        <Layout style={{ height: "100vh" }}>
          <Layout.Header>
            <div className="app-cm1-logo"></div>
            <div style={{ float: 'right', marginRight: -20, userSelect: 'none' }}>
              <UserMenu
                i18n={i18n}
                ready={ready}
                t={t}
                includeReloadRate={true} 
                german={german}
                english={english}
                showCanvasAllBeacons={showCanvasAllBeacons}
                toggleCanvas={toggleCanvas}
              />
            </div>
            <div style={{ float: 'right', marginRight: '1em', transform: 'translateY(4px)', userSelect: 'none' }}>
              <ReloadOutlined title="Reload" className="clickable" onClick={onRefreshClick} />
            </div>
            <NavigationMenu />
          </Layout.Header>
          <Layout.Content style={{ padding: 10 }}>
            <Switch>
              {routes(user).map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact
                  children={<route.component />}
                />
              ))}
              <Redirect key="redirect" to={redirectPath} />
            </Switch>
          </Layout.Content>
        </Layout>
      </BeaconDrawerContextProvider>
    </BrowserRouter>
  );
};

function App() {
  const { t } = useTranslation();
  const [user, setUser] = useState<CurrentUser | undefined>(undefined);
  const [refreshCounter, setRefreshCounter] = useState(1);
  const [autoRefreshInterval, setAutoRefreshInterval] = useSetting('auto-refresh-interval', 60000);
  const [autoRefreshDisabled, setAutoRefreshDisabled] = useState(false);
  const enableAutoRefresh = useCallback(() => setAutoRefreshDisabled(false), [setAutoRefreshDisabled]);
  const disableAutoRefresh = useCallback(() => setAutoRefreshDisabled(true), [setAutoRefreshDisabled]);

  const [showCanvasAllBeacons, setShowCanvasAllBeacons] = useState(true);
  const toggleCanvas = (checked: boolean) => {
    setShowCanvasAllBeacons(checked);
  };

  const handleAuthStateChange = async (a: any, c?: any) => {
    if (c?.attributes?.email) {
      const session = await Auth.currentSession();
      const accessToken = new AccessToken(session.getAccessToken());
      setUser({
        email: c?.attributes?.email,
        accessToken
      });
    }
    else {
      setUser(undefined);
    }
  };
  
  const onRefresh = (againWithDelay?: boolean) => {
    setRefreshCounter(c => c + 1);
    if (againWithDelay) {
      setTimeout(() => setRefreshCounter(c => c + 1), 2000);
    }
  };

  const onRefreshClick = () => { onRefresh(); };

  const getRedirectPath = () => {
    if (user?.accessToken.hasRole('road-safety-manager')) {
      return 'construction-projects';
    } else if (user?.accessToken.hasRole('solution-operator')) {
      return 'beacons';
    } else if (user?.accessToken.hasRole('user-administrator')) {
      return 'organizations';
    } else {
      return 'users';
    }
  };

  const { i18n, ready } = useTranslation();
  const german = useCallback(() => { i18n.changeLanguage('de') }, [i18n]);
  const english = useCallback(() => { i18n.changeLanguage('en') }, [i18n]);

  return (
    <Environment>
      <AmplifyAuthenticator handleAuthStateChange={handleAuthStateChange}>
        <AuthContext.Provider value={{ currentUser: user }}>
          <DataRefreshContext.Provider value={{ refreshCounter, refresh: onRefresh }}>
            <DataAutoRefreshContext.Provider value={{ interval: autoRefreshInterval, setInterval: setAutoRefreshInterval, disabled: autoRefreshDisabled, disable: disableAutoRefresh, enable: enableAutoRefresh }}>
              {!!user && user.accessToken.hasRole('road-safety-manager') && (
              <VerifyRoadSafetyManagerHasAnOrganization showCanvasAllBeacons={showCanvasAllBeacons} toggleCanvas={toggleCanvas}>
                <AppLayout user={user} onRefreshClick={onRefreshClick} redirectPath={getRedirectPath()} showCanvasAllBeacons={showCanvasAllBeacons} toggleCanvas={toggleCanvas} />
              </VerifyRoadSafetyManagerHasAnOrganization>
              )}
              {!!user && !user.accessToken.hasRole('road-safety-manager') && (
              <AppLayout user={user} onRefreshClick={onRefreshClick} redirectPath={getRedirectPath()} showCanvasAllBeacons={showCanvasAllBeacons} toggleCanvas={toggleCanvas} />
              )}
              {!user && (
              <BrowserRouter>
                <BeaconDrawerContextProvider>
                  <Layout style={{ height: '100vh' }}>
                    <Layout.Header>
                      <div className="app-cm1-logo"></div>
                      <div style={{ float: 'right', marginRight: -20, userSelect: 'none' }}>
                        <UserMenuNoOrganizationsAttached
                          i18n={i18n}
                          ready={ready}
                          t={t}
                          includeReloadRate={true}
                          german={german}
                          english={english}
                          showCanvasAllBeacons={showCanvasAllBeacons}
                          toggleCanvas={toggleCanvas}
                        />
                      </div>
                    </Layout.Header>
                  </Layout>
                </BeaconDrawerContextProvider>
                <NoOrganization />
              </BrowserRouter>
              )}
              {!!user && !user.accessToken.hasRole('solution-operator') && !user.accessToken.hasRole('user-administrator') && !user.accessToken.hasRole('road-safety-manager') && (
                <NoOrganization />
              )}
            </DataAutoRefreshContext.Provider>
          </DataRefreshContext.Provider>
        </AuthContext.Provider>
      </AmplifyAuthenticator>
    </Environment>
  );
}

export default App;