export function groupBy<T, K>(xs: T[], key: (v: T) => K) {
    const map = groupByAsMap<T, K>(xs, key);

    const result: { key: K, items: T[] }[] = [];

    map.forEach((items: T[], k: K) => {
        result.push({ key: k, items });
    });

    return result;
};

export function groupByAsMap<T, K>(xs: T[], key: (v: T) => K) {
    return xs.reduce(function (rv: Map<K, T[]>, x) {
        const k = key(x);
        let items = rv.get(k);
        if (!items) {
            items = [];
            rv.set(k, items);
        }
        items.push(x);
        return rv;
    }, new Map<K, T[]>());
}

export function toDictionary<T, K>(xs: T[], key: (v: T) => K) {
    const map = new Map<K, T>();
    xs.forEach(i => {
        map.set(key(i), i);
    });
    return map;
}