import { CheckCircleOutlined, PoweroffOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Drawer, Spin, Timeline } from "antd";
import React, { useCallback, useContext } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Beacon, BeaconError, BeaconLifecycle, BeaconWithPosition, isOff, isOn, BeaconOnOffSwitch, useActiveErrors, useEnvironmentHistory, useErrorHistory, useLifecycleHistory, usePositionHistory, useErrorTranslation, ErrorSeverity, BeaconActiveErrorResponse } from "../../api";
import { AuthContext } from "../../contexts/authContext";
import { maxBy, useTransformedState } from "../../util";
import { BeaconDetailMap } from "../BeaconDetailMap";
import { BeaconErrorStatus } from "../BeaconErrorStatus";
import { BeaconErrors } from "../BeaconsErrors";
import { BeaconStatus } from "../BeaconStatus";
import { TimeRangeSelection, useTimeRangeSelection } from "../TimeRangeSelection";

interface BeaconDrawerProps {
    onClose(): void;
    beacon: Beacon | undefined;
}

interface BeaconDrawerContentProps {
    beacon: Beacon;
}

type TimelineItem = {
    text: React.ReactNode;
    time: Date;
    icon?: React.ReactNode;
    type: 'ERROR_ACTIVATION' | 'ERROR_DEACTIVATION' | 'ERROR_ACKNOWLEDGEMENT' | 'POWER_ON' | 'POWER_OFF',
    tag?: any;
};

function pushErrors(t: (code: string, severity: ErrorSeverity, resolved: boolean) => string, tl: TimelineItem[], message: BeaconError) {
    tl.push({
        text: t(message.value.code, message.value.severity, !message.value.active),
        time: message.measuredAt,
        icon: message.value.active ? <BeaconErrorStatus severity={message.value.severity} /> : <CheckCircleOutlined style={{ color: 'green' }} />,
        type: message.value.active ? 'ERROR_ACTIVATION' : 'ERROR_DEACTIVATION',
        tag: message.value.code
    });
}

function pushErrorAcknowledgements(t: (code: string, severity: ErrorSeverity, resolved: boolean, acknowledged: boolean) => string, tl: TimelineItem[], message: BeaconActiveErrorResponse) {
    if (message.hasBeenRead && message.hasBeenReadAt)
        tl.push({
            text: t(message.code, message.severity, false, true),
            time: message.hasBeenReadAt,
            icon: <CheckCircleOutlined />,
            type: 'ERROR_ACKNOWLEDGEMENT'
        });
}

function pushOnOff(t: TFunction<'translation'>, tl: TimelineItem[], message: BeaconLifecycle) {
    if (isOn(message.value)) {
        tl.push({ text: t('beacon:turned-on'), time: message.measuredAt, icon: <PoweroffOutlined style={{ color: 'green' }} />, type: 'POWER_ON' });
    } else if (isOff(message.value)) {
        tl.push({ text: t('beacon:turned-off'), time: message.measuredAt, icon: <PoweroffOutlined style={{ color: 'black' }} />, type: 'POWER_OFF' });
    }
}

function pushOnOffSwitch(t: TFunction<'translation'>, tl: TimelineItem[], onOff: BeaconOnOffSwitch) {
    if (onOff.isOn) {
        tl.push({ text: t('beacon:turned-on'), time: onOff.lastReportedAt, icon: <PoweroffOutlined style={{ color: 'green' }} />, type: 'POWER_ON' });
    } else {
        tl.push({ text: t('beacon:turned-off'), time: onOff.lastReportedAt, icon: <PoweroffOutlined style={{ color: 'black' }} />, type: 'POWER_OFF' });
    }
}


function BeaconDrawerContent(props: BeaconDrawerContentProps) {

    const { t } = useTranslation();
    const errorTranslation = useErrorTranslation();

    const { beacon } = props;

    const { selectedFromToFilter, fromToFilterOptions, selectedFromToFilterOptionIndex, onSelectFilterOption, onSelectCustomTimeFrame } = useTimeRangeSelection(beacon.id);

    const positionHistory = usePositionHistory(undefined, beacon?.id, selectedFromToFilter);
    const environmentHistory = useEnvironmentHistory(undefined, beacon.id, selectedFromToFilter);
    const errorHistory = useErrorHistory(beacon.id, selectedFromToFilter, 10000);
    const lifecycleHistory = useLifecycleHistory(beacon.id, selectedFromToFilter, 10000);
    const activeErrors = useActiveErrors(beacon.id);

    const beaconWithPos = beacon as BeaconWithPosition;
    const newestPosition = beaconWithPos.position || maxBy(positionHistory.value || [], p => p.measuredAt.getTime())?.item;

    const timeline: TimelineItem[] = useTransformedState<undefined, TimelineItem[]>(undefined,
        () => {
            const err = errorHistory.value;
            const life = lifecycleHistory.value;

            const tl: TimelineItem[] = [];

            err?.items?.forEach(m => pushErrors(errorTranslation, tl, m));
            life?.items?.forEach(m => pushOnOff(t, tl, m));
            activeErrors?.value?.forEach(m => pushErrorAcknowledgements(errorTranslation, tl, m));

            tl.sort((t1: TimelineItem, t2: TimelineItem) => t2.time > t1.time ? 1 : t1.time === t2.time ? 0 : -1);

            let resortRequired = false;

            if (selectedFromToFilterOptionIndex === 0) {
                if (activeErrors.value?.length) {
                    activeErrors.value.forEach(e => {
                        const lastActivation = tl.find(i => i.type === 'ERROR_ACTIVATION' && i.tag === e.code);
                        const lastDeactivation = tl.find(i => i.type === 'ERROR_DEACTIVATION' && i.tag === e.code);

                        const activationIsNotShown = !lastActivation;
                        const oldActivationIsShown = lastActivation && lastDeactivation && lastDeactivation.time > lastActivation.time;

                        if (activationIsNotShown || oldActivationIsShown) {
                            tl.push({
                                text: errorTranslation(e.code, e.severity),
                                time: e.startedAt,
                                icon: <BeaconErrorStatus severity={e.severity} />,
                                type: 'ERROR_ACTIVATION',
                                tag: e.code
                            });
                            resortRequired = true;
                        }

                    });
                }

                if (!tl.some(t => t.type === 'POWER_ON') && beacon.onOffSwitch?.isOn) {
                    pushOnOffSwitch(t, tl, beacon.onOffSwitch);
                    resortRequired = true;
                }

                if (!tl.some(t => t.type === 'POWER_OFF') && beacon.onOffSwitch && !beacon.onOffSwitch.isOn) {
                    pushOnOffSwitch(t, tl, beacon.onOffSwitch);
                    resortRequired = true;
                }
            }

            if (resortRequired) {
                tl.sort((t1: TimelineItem, t2: TimelineItem) => t2.time > t1.time ? 1 : t1.time === t2.time ? 0 : -1);
            }

            return tl;
        }, [errorHistory.value, lifecycleHistory.value, activeErrors.value, t, errorTranslation, selectedFromToFilterOptionIndex, beacon]);

    const loadingTimeline = positionHistory.loading || environmentHistory.loading || errorHistory.loading || lifecycleHistory.loading;

    return <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: '1em', height: '100%' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto 1fr', gridColumnGap: '1em' }}>
            <div style={{ marginTop: beacon.activeErrors?.length ? 14 : 0 }}>
                <BeaconStatus beacon={beacon} hideErrorStatus />
            </div>
            <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
                <BeaconErrors beacon={beacon} />
            </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '340px 1fr', gridColumnGap: '1em' }}>
            <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr', gridRowGap: '1em' }}>
                <TimeRangeSelection fromToFilterOptions={fromToFilterOptions} selectedFromToFilterOptionIndex={selectedFromToFilterOptionIndex} onSelectFilterOption={onSelectFilterOption} onSelectCustomTimeFrame={onSelectCustomTimeFrame} />
                <div style={{ overflowY: 'auto', overflowX: 'visible', position: 'relative' }}>
                    <div style={{ position: 'absolute', left: 0, width: '100%', top: 0, height: '100%', paddingTop: 8, paddingLeft: 4 }}>
                        {!timeline.length
                            ? (loadingTimeline ? <Spin /> : <Alert message={t('no-data')} type="info" showIcon />)
                            : <Timeline>
                                {timeline?.map((t, i) => <Timeline.Item key={'timeline_' + i} dot={t.icon}>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridColumnGap: '1em' }}>
                                        <span style={{ whiteSpace: 'nowrap' }}>{t.time.toLocaleString()}</span>
                                        {t.text}
                                    </div></Timeline.Item>)}
                            </Timeline>
                        }
                    </div>
                </div>
            </div>
            <BeaconDetailMap
                loading={positionHistory.loading}
                lastLoadedAt={positionHistory.lastLoadedAt}
                beacon={beacon}
                position={newestPosition}
                activeErrors={activeErrors.value} />
        </div>
    </div>
}

export function BeaconDrawer(props: BeaconDrawerProps) {
    const { t } = useTranslation();
    const { beacon, onClose } = props;

    const history = useHistory();

    const { currentUser } = useContext(AuthContext);
    const currentUserIsSolutionOperator = currentUser?.accessToken?.hasRole('solution-operator');
    const currentUserIsRoadSafetyManager = currentUser?.accessToken?.hasRole('road-safety-manager');

    const navigateToBeacon = useCallback(() => {
        if (!beacon) {
            return;
        }

        onClose();
        history.push(`/beacons/${beacon.id}`);
    }, [history, beacon, onClose]);

    const drawerWidth: React.CSSProperties['width'] = window.innerWidth < 800
        ? '100%'
        : window.innerWidth > 1800
            ? '1170px'
            : '65%';

    return <Drawer visible={!!beacon} onClose={onClose} title={<div>
    <span>{beacon?.serial || beacon?.id}</span>
    {currentUserIsRoadSafetyManager && <a style={{ marginLeft: '1em', fontWeight: 'normal' }} onClick={navigateToBeacon}>{t('beacon:show-details')}</a>}
    </div>} width={drawerWidth} maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}>
    {!!beacon && <BeaconDrawerContent beacon={beacon} />}
    </Drawer>;
}