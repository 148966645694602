import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAssignBeaconsToOrganization } from "../../api";
import { SelectBeaconsModal } from "../../components/SelectBeaconsModal";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";

interface AssignBeaconsModalProps {
    organizationId: string;
}

export function AssignBeaconsModal(props: AssignBeaconsModalProps) {
    const { organizationId } = props;
    const { t } = useTranslation();
    const assignBeaconsToOrganization = useAssignBeaconsToOrganization();
    const dataRefreshContext = useContext(DataRefreshContext);

    const onAssignBeaconsToOrganization = async (beaconIds: string[]) => {
        await assignBeaconsToOrganization({ beaconIds, organizationId });
        dataRefreshContext.refresh();
    }

    return <SelectBeaconsModal
        title={t('organization:assign-beacons')}
        instruction={t('organization:assign-beacons-drawing-tooltip')}
        assignedToOrganization={false}
        selectButtonText={beaconIds => t('organization:assign-beacons', { count: beaconIds.length })}
        selectButtonTitle={beaconIds => t('organization:assign-beacons-message', { count: beaconIds.length })}
        modalButtonIcon={<PlusCircleOutlined />}
        onBeaconsSelected={onAssignBeaconsToOrganization} />
}