import { TablePaginationConfig } from "antd";
import { PagedApiState } from "../api/useApiState";

export function toAntdPagination<T>(page: PagedApiState<T>): TablePaginationConfig {
    return {
        hideOnSinglePage: true,
        current: page.currentPage + 1,
        pageSize: page.currentPageSize,
        total: page.value?.totalCount,
        showSizeChanger: true,
        pageSizeOptions: ['10', '15', '50', '100'],
        onChange: (newPage, newPageSize) => {
            newPage -= 1;
            if (newPage !== page.currentPage) {
                page.goToPage(newPage);
            }

            if (newPageSize && newPageSize !== page.currentPageSize) {
                page.setPageSize(newPageSize);
            }
        }
    };
}