import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { jsonDeserialize, jsonSerialize, useSetting } from "../util";

interface CollapsibleProps {
    left: React.ReactNode;
    expanderTitle: string;
    right: React.ReactNode;
    stateKey: string;
}

export function Collapsible(props: CollapsibleProps) {

    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsed] = useSetting(props.stateKey, true, jsonSerialize, jsonDeserialize);
    const collapse = useCallback(() => setIsCollapsed(true), [setIsCollapsed]);
    const expand = useCallback(() => setIsCollapsed(false), [setIsCollapsed]);

    return <div style={{ position: 'relative', height: '100%', display: 'grid', gridTemplateColumns: isCollapsed ? 'auto 1fr' : '1fr 1fr', columnGap: '1em' }}>


        <div style={{ cursor: isCollapsed ? 'pointer' : 'default', position: 'relative' }} onClick={isCollapsed ? expand : undefined}>
            {isCollapsed && <>
                <div style={{ transform: 'translate(-50%, -50%) rotate(-90deg)', whiteSpace: 'nowrap', position: 'absolute', top: '50%', left: '50%', fontWeight: 'bold' }}>{props.expanderTitle}</div>
                <div style={{ width: '38px', backgroundColor: '#F0F2F5', height: '100%' }} title={t('expand')}></div>
                <RightCircleOutlined style={{ position: 'absolute', top: '8px', right: '8px' }} title={t('expand')} onClick={expand} />
            </>}

            {!isCollapsed && <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr' }}>
                <div style={{ position: 'relative', height: '38px', cursor: 'pointer', backgroundColor: '#F0F2F5' }} title={t('collapse')} onClick={collapse}>
                    <LeftCircleOutlined style={{ position: 'absolute', top: '8px', right: '8px' }} />
                </div>
                {props.left}
            </div>}
        </div>
        {props.right}
    </div>
}