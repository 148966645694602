import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from '@ant-design/icons';
import { useAssignUsersToOrganization } from "../../api";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { SelectUsersModal } from "../../components/SelectUsersModal";

interface AssignUsersModalProps {
    organizationId: string;
}

export function AssignUsersModal(props: AssignUsersModalProps) {
    const { organizationId } = props;
    const { t } = useTranslation();
    const assignUsersToOrganization = useAssignUsersToOrganization();
    const dataRefreshContext = useContext(DataRefreshContext);

    const onAssignUsersToOrganization = async (userIds: string[]) => {
        await assignUsersToOrganization({ userIds, organizationId });
        dataRefreshContext.refresh();
    }

    return <SelectUsersModal
        title={t('organization:assign-users')}
        assignedToOrganization={false}
        selectButtonText={userIds => t('organization:assign-users', { count: userIds.length })}
        selectButtonTitle={userIds => t('organization:assign-users-message', { count: userIds.length })}
        modalButtonIcon={<PlusCircleOutlined />}
        onUsersSelected={onAssignUsersToOrganization} />
}