import { useCallback } from "react";
import { unparse } from 'papaparse';
import { useTranslation } from "react-i18next";

export interface CsvColumn<T> {
    title: string;
    value(p: T, culture: string, beaconId: string): any;
}

const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function useDownloadCsv<T>(beaconId: string, items: T[], columns: CsvColumn<T>[], fileName: string){
    const { t } = useTranslation();
    const culture = t('culture');
    const download = useCallback(() => {
        var csv = unparse(
            items.map(p => columns.reduce((v: any, c) => {
                const nv = {
                    ...v,
                };
                nv[c.title] = c.value(p, culture, beaconId);
                return nv;
            }, {})), { delimiter: ';' });

        const type = isSafari() ? 'application/csv' : 'text/csv';
        const blob = new Blob(['', csv], { type });
        const dataURI = `data:${type};charset=utf-8,${csv}`;

        const URL = window.URL || window.webkitURL;

        const uri = (typeof URL.createObjectURL === 'undefined')
            ? dataURI
            : URL.createObjectURL(blob);

        var link = document.createElement("a");
        link.href = uri;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }, [culture, items.length, beaconId, fileName]);

    return download;
}