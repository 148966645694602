import { Button, Modal } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllUsersTable } from './UserTable';

interface SelectUsersModalProps {
    modalButtonIcon?: React.ReactNode;
    title: string;
    selectButtonText(userIds: string[]): string;
    selectButtonTitle(userIds: string[]): string;
    assignedToOrganization?: boolean;
    onUsersSelected(userIds: string[]): Promise<any>;
}

export function SelectUsersModal(props: SelectUsersModalProps) {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

    const onSelect = async () => {
        setIsSelecting(true);
        try {
            await props.onUsersSelected(selectedUserIds);
        } catch {
            setIsSelecting(false);
            return;
        }
        setIsOpen(false);
        setIsSelecting(false);
        setSelectedUserIds([]);
    }

    const onCancel = () => {
        if (isSelecting) {
            return;
        }
        setIsOpen(false);
        setIsSelecting(false);
        setSelectedUserIds([]);
    }

    const modalFooter = [
        <Button key="cancel" disabled={isSelecting} onClick={onCancel}>{t('cancel')}</Button>,
        <Button key="select" disabled={isSelecting || !selectedUserIds.length}
            loading={isSelecting}
            onClick={onSelect}
            title= {props.selectButtonTitle(selectedUserIds)}
            type="primary">
            {props.selectButtonText(selectedUserIds)}
        </Button>
    ];

    return <>
        <Button onClick={() => setIsOpen(true)} type="primary">{props.modalButtonIcon}{props.title}</Button>
        <Modal title={props.title} visible={isOpen} width={'50%'} bodyStyle={{maxHeight: window.innerHeight * 0.7, overflowY: 'auto'}} onCancel={onCancel} onOk={onSelect} footer={modalFooter}>
           <AllUsersTable hideFooter assignedToOrganization={props.assignedToOrganization} {...{selectedUserIds: selectedUserIds, setSelectedUserIds: setSelectedUserIds}} />
        </Modal>
    </>;
}