import { Address as AddressModel } from "../api";

export function Address(props: { address?: AddressModel }) {
    const { address } = props;
    if (!address) {
        return <span data-testid="addressString">-</span>;
    }

    let stringified = address.zipCode;
    if (address.city) {
        stringified = stringified ? `${stringified} ${address.city}` : address.city;
    }

    if(address.street){
        stringified = stringified ? `${stringified}, ${address.street}` : address.street;
    }

    return <span data-testid="addressString">{stringified}</span>
}