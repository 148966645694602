import { Tag, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { Beacon, BeaconEnvironment, ErrorSeverity } from "../api";
import './Battery.css';
import { BeaconActiveErrorsTooltip } from "./BeaconActiveErrorsTooltip";
import { BeaconErrorStatus } from "./BeaconErrorStatus";

export function Battery(props: { environment: BeaconEnvironment; iconOnly?: boolean; }) {
    const { t } = useTranslation();
    const { environment } = props;
    const voltage = environment.value.batt.v;
    const percentage = environment.value.remainingRuntimeRatio;
    const roundedPercentage = Math.round(percentage * 100);

    const days = Math.floor(environment.value.remainingRuntimeHours / 24);

    const tooltip = days < 1
        ? t('beacon:battery-title-less-than-a-day', { voltage: voltage.toFixed(2).toLocaleString() })
        : t('beacon:battery-title', { count: days, days, voltage: voltage.toFixed(2).toLocaleString() });

    return <span title={tooltip} style={{ position: 'relative', width: 37 }} data-testid="Battery">
        <span className="battery-container">
            <span className="battery-bump"></span>
            <span className="battery-outer">
                <span className={'battery-level' + (percentage < 0.1 ? ' alert' : percentage < 0.3 ? ' warn' : '')} style={{ height: roundedPercentage + '%' }}></span>
            </span>
        </span>
        {!props.iconOnly && <span style={{ fontSize: 12, position: 'absolute', top: 1, left: 14 }} data-testid="BatteryPercentage">{roundedPercentage}%</span>}
    </span>;
}

export function BeaconStatus(props: { beacon: Beacon; hideErrorStatus?: boolean }) {

    const { t } = useTranslation();

    const { beacon, hideErrorStatus } = props;

    const hasWarningsOrErrors = !!beacon?.activeErrors?.length;

    return <div style={{ marginLeft: 3, display: 'grid', gridTemplateColumns: 'auto auto auto 1fr' }} data-testid="BeaconStatus">
        {!beacon.onOffSwitch
            ? <Tag color='gray'>{t('beacon:is-on-off-unknown')}</Tag>
            : beacon.onOffSwitch.isOn
                ? <Tag color='green' style={{ width: 40, textAlign: 'center' }} title={t('beacon:is-on-off-last-reported-at', { lastReportedAt: beacon.onOffSwitch.lastReportedAt.toLocaleString() })}>{t('beacon:is-on-off-on')}</Tag>
                : <Tag color='black' style={{ width: 40, textAlign: 'center' }} title={t('beacon:is-on-off-last-reported-at', { lastReportedAt: beacon.onOffSwitch.lastReportedAt.toLocaleString() })}>{t('beacon:is-on-off-off')}</Tag>}
        {beacon.onOffSwitch?.isOn && !!beacon.environment?.value?.batt && <Battery environment={beacon.environment} />}
        {beacon.onOffSwitch?.isOn && !hideErrorStatus && hasWarningsOrErrors && <BeaconErrorStatus errors={beacon?.activeErrors} style={{ marginLeft: '8px' }} showTooltip />}
    </div>;
}