import { Button, DatePicker, Modal } from 'antd';
import moment, { Moment } from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface TimeRangePickerProps {
    from?: Date;
    to?: Date;
    title?: string;
    onSelected(from: Date, to: Date): void;
}

export function TimeRangePicker(props: TimeRangePickerProps) {

    const { t } = useTranslation();

    const [range, setRange] = useState<[Moment | null, Moment | null] | null>(null);
    const [isOpen, setIsOpen] = useState(false);

    const open = useCallback(() => {
        setIsOpen(true);
        const from = props.from ? moment(props.from) : moment(new Date());
        const to =  props.to ? moment(props.to) : moment(new Date());
        setRange([from, to]);
    }, [setIsOpen, setRange, props.from, props.to]);

    const cancel = useCallback(() => {
        setIsOpen(false);
        setRange(null);
    }, [setIsOpen, setRange]);

    const okDisabled = !range || !range[0] || !range[1] || range[1].isBefore(range[0]);

    const ok = useCallback(() => {
        if (okDisabled) {
            return;
        }
        setIsOpen(false);
        setRange(null);
        props.onSelected(range![0]!.toDate(), range![1]!.toDate());

    }, [setIsOpen, setRange, props.onSelected, range, okDisabled]);

    return <>
        <div>
            {t('time-range-picker:user-defined')}
            <Button style={{ marginLeft: '1em' }} onClick={open}>{props.title ?? t('time-range-picker:user-defined-select')}</Button>
        </div>
        <Modal visible={isOpen} onOk={ok} onCancel={cancel} okText={t('ok')} cancelText={t('cancel')} title={t('time-range-picker:title')} okButtonProps={{ disabled: okDisabled }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr', columnGap: '1em' }}>
                <DatePicker.RangePicker showTime={{ defaultValue: [moment().startOf('day'), moment().endOf('day')] }} value={range} onChange={setRange} placeholder={[t('time-range-picker:from'), t('time-range-picker:to')]} />
            </div>
        </Modal>
    </>
}