import { useContext } from 'react';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Menu } from 'antd';
import '../App.css';
import { routes } from '../routes';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../contexts/authContext';

export function NavigationMenu(this: any) {
    const location = useLocation();
    const { t } = useTranslation();
    const match = useRouteMatch({ exact: true, path: location.pathname });
    const history = useHistory();
    const path = match?.path?.trim() || '<none>';

    const authContext = useContext(AuthContext);
    const routesInMenu = routes(authContext.currentUser!).filter(r => r.navigationMenu);

    const onNav = (key: string) => {
        history.push(routesInMenu[parseInt(key)].path);
    };

    const matchingRoutes = routesInMenu.filter(r => path.startsWith(r.path));
    let matchingRoute = matchingRoutes.length ? matchingRoutes[0] : undefined;
    if (matchingRoute && matchingRoutes.length > 1) {
        for (let i = 1; i < matchingRoutes.length; i++) {
            if (matchingRoutes[i].path.length > matchingRoute.path.length) {
                matchingRoute = matchingRoutes[i];
            }
        }
    }

    const selectedKeys = matchingRoute ? [routesInMenu.indexOf(matchingRoute).toString(10)] : [];

    return <Menu theme="dark" mode="horizontal" selectedKeys={selectedKeys} onClick={e => onNav(e.key.toString())}>
        {routesInMenu.map((r, i) => <Menu.Item key={i.toString()}>{t(r.navigationMenu!.labelKey)}</Menu.Item>
        )}
    </Menu>;
}