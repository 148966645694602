import { useCallback, useEffect, useState } from "react";

const emptyArray: any[] = [];

export function useTransformedState<TSource, TTarget>(source: TSource, transform: (s: TSource) => TTarget, dependency?: any) {
    const [target, setTarget] = useState(transform(source));
    const dependencies = Array.isArray(source)
        ? [source.length ? source : emptyArray, source.length, setTarget]
        : [source, setTarget];
    if (dependency) {
        if (Array.isArray(dependency)) {
            dependency.forEach(d => dependencies.push(d));
        } else {
            dependencies.push(dependency);
        }
    }
    useEffect(() => {
        setTarget(transform(source));
    }, dependencies);

    return target;
}