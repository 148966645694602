import { useTranslation } from "react-i18next";

export function NoRole() {
    const { t } = useTranslation();
    return <div style={{ height: '100%' }}>
        <div style={{ display: 'grid', gridTemplateRows: 'auto auto', gridRowGap: '1em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: 'large' }}>{t('no-role-header')}</span>
            <span>{t('no-role-message')}</span>
        </div>
    </div>;
}

export function NoOrganization() {
    const { t } = useTranslation();
    return <div style={{ height: '100%' }}>
        <div style={{ display: 'grid', gridTemplateRows: 'auto auto', gridRowGap: '1em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: 'large' }}>{t('no-org-header')}</span>
            <span>{t('no-org-message')}</span>
        </div>
    </div>;
}