import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Breadcrumb, Spin } from 'antd';
import { useBeacon } from '../../api';
import { BeaconDetails } from '../../components/BeaconDetails';
import { BeaconConfigurationModal } from '../../components/beacon-configuration/BeaconConfigurationModal';

export function Beacon() {
    const { t } = useTranslation();
    const history = useHistory();
    const { beaconId } = useParams<{ beaconId: string }>();
    const beacon = useBeacon(beaconId);
    if (!beacon.value) {
        return <Spin size='large' />;
    }

    return <BeaconDetails beacon={beacon.value} breadcrumb={
      <Breadcrumb className='breadcumb-header'>
        <Breadcrumb.Item>
          <a href='/beacons' onClick={e => { e.preventDefault(); history.push('/beacons') }}>{t('beacons')}</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {beacon.value ? (beacon.value.serial || beacon.value.id) : beaconId}
          {beacon.value && <BeaconConfigurationModal  beacon={beacon.value} />}
        </Breadcrumb.Item>
      </Breadcrumb>
      } 
    />;
}