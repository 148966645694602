import { Button, Modal, Input, Switch } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ForwardToPlatform, Organization, OrganizationMutableProperties } from '../api';

interface OrganizationPropertyModalProps {
    modalButtonIcon?: React.ReactNode;
    title: string;
    organization?: Organization;
    onSave(organization: OrganizationMutableProperties): Promise<any>
}

export function OrganizationPropertyModal(props: OrganizationPropertyModalProps) {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [newName, setNewName] = useState<string>();
    const [newForward, setNewForward] = useState<ForwardToPlatform | undefined>();

    const effectiveName = newName ?? props.organization?.name;
    const effectiveForward = newForward ?? props.organization?.forwardToPlatform;

    const onSave = async () => {
        setIsSaving(true);
        try {
            await props.onSave({
                name: effectiveName!,
                forwardToPlatform: effectiveForward
            });
        } catch {
            setIsSaving(false);
            return;
        }
        setNewName(undefined);
        setNewForward(undefined);
        setIsOpen(false);
        setIsSaving(false);
    }

    const onCancel = () => {
        if (isSaving) {
            return;
        }
        setNewName(undefined);
        setNewForward(undefined);
        setIsOpen(false);
    }

    const modalFooter = [
        <Button key="cancel" disabled={isSaving} onClick={onCancel}>{t('cancel')}</Button>,
        <Button key="save" disabled={isSaving || !effectiveName || (effectiveName === props.organization?.name && effectiveForward === props.organization?.forwardToPlatform)}
            loading={isSaving}
            onClick={onSave}
            type="primary">
            {t('save')}
        </Button>
    ];

    return <>
        <Button onClick={() => setIsOpen(true)} type="primary">{props.modalButtonIcon}{props.title}</Button>
        <Modal title={props.title} visible={isOpen} onCancel={onCancel} onOk={onSave} footer={modalFooter}>
            <div className="vertical-form">
                <span className="label">{t('organization:name')}</span>
                <Input value={effectiveName} onChange={e => setNewName(e.target.value)} placeholder={t('organization:name')} />
                <span className="label">{t('organization:forward-to-udp')}</span>
                <div className="full">
                    <Switch checked={effectiveForward === ForwardToPlatform.UDP} onChange={c => setNewForward(c ? ForwardToPlatform.UDP : ForwardToPlatform.NONE)} checkedChildren={t('yes')} unCheckedChildren={t('no')} />
                </div>
                <span className="label">{t('organization:forward-to-movement-mail')}</span>
                <div className="full">
                    <Switch checked={effectiveForward === ForwardToPlatform.MOVEMENT_MAIL_ALERTING} onChange={c => setNewForward(c ? ForwardToPlatform.MOVEMENT_MAIL_ALERTING : ForwardToPlatform.NONE)} checkedChildren={t('yes')} unCheckedChildren={t('no')} />
                </div>
            </div>
        </Modal>
    </>;
}