import { useEffect, useState } from "react";

export function useIncrementingCounter(intervalMs: number, disabled?: boolean) {
    const [counter, setCounter] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            if (!disabled) {
                setCounter(c => c + 1);
            }
        }, intervalMs);
        return () => {
            clearInterval(interval);
        };
    }, [intervalMs, disabled]);
    return counter;
}
