import { Beacon, useUpdateBeacon } from '../api';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext } from 'react';
import { AuthContext } from '../contexts/authContext';
const { confirm } = Modal;

interface BeaconEnableDisableButtonProps {
    beacon: Beacon;
    onUpdated(): void;
}

export function BeaconEnableDisableButton(props: BeaconEnableDisableButtonProps) {
    const { beacon } = props;
    const { t } = useTranslation();
    const updateBeacon = useUpdateBeacon(beacon.id);
    const authContext = useContext(AuthContext);
    const currentUserIsSolutionOperator = authContext.currentUser?.accessToken?.hasRole('solution-operator');

    const onSave = useCallback(async () => {
        await updateBeacon({ isDisabled: !beacon.isDisabled });
        props.onUpdated();
    }, []);

    const action = beacon.isDisabled ? 'enable' : 'disable';

    const open = useCallback((e:React.MouseEvent) => {
        e.preventDefault();
        confirm({
            title: t(`beacon:${action}-sure`, { id: beacon.serial || beacon.id }),
            icon: <ExclamationCircleOutlined />,
            okType: 'danger',
            okText: t('ok'),
            onOk: onSave,
            cancelText: t('cancel'),
            onCancel() { },
        });
    }, []);

    if(!currentUserIsSolutionOperator){
        return <></>;
    }

    return <Button onClick={open}>{t(`beacon:${action}`)}</Button>;
}