import { Dropdown, Menu } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Beacon } from '../api';
import { BeaconConfigurationModal } from './beacon-configuration/BeaconConfigurationModal';

interface BeaconManagementMenuProps {
    beacon: Beacon;
    onUpdated(): void;
}

export function BeaconManagementMenu(props: BeaconManagementMenuProps & { style?: React.CSSProperties }) {
    return <Dropdown trigger={['click']} overlay={<Menu className="actions-menu">
        <Menu.Item>
            <BeaconConfigurationModal {...props} />
        </Menu.Item>
    </Menu>}>
        <SettingOutlined style={props.style} />
    </Dropdown>
}