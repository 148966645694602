import { useCallback, useState } from 'react';
import { Map as LeafletMap, LatLngBounds } from 'leaflet';
import { BeaconWithPosition, LinearRing, useGetBeaconsInArea, useGetConstructionProjectSite } from '../api';
import { getDefaultMapPosition, Map, MapPolygon, MapPosition } from './Map';
import { PolygonEditControl } from './PolygonEditControl';
import { Maximizable } from './Maximizable';
import { boundsToArea } from './SelectBeaconsOnMap';
import { groupBy } from '../util';
import { useTranslation } from 'react-i18next';
import { DrawingInstruction } from './DrawingInstruction';

interface SelectAreaProps {
    area?: LinearRing;
    setArea(area?: LinearRing): void;
    height?: string | number;
    constructionProjectId: string | undefined;
    onMapReady?: (map: LeafletMap) => void;
    defaultMapPosition: MapPosition | undefined;
}

export function SelectArea(props: SelectAreaProps) {
    const { area, setArea } = props;

    const { t } = useTranslation();
    const getBeaconsInArea = useGetBeaconsInArea();
    const getConstructionProjectSite = useGetConstructionProjectSite();
    const [beaconsInArea, setBeaconsInArea] = useState<BeaconWithPosition[]>();
    const [constructionSitesInArea, setConstructionSitesInArea] = useState<MapPolygon[]>();

    const defaultPosition = area ? getDefaultMapPosition(undefined, [{ ring: area }]) : props.defaultMapPosition;

    const [map, setMap] = useState<LeafletMap>();

    const loadBeacons = useCallback(async (zoom: number, bounds: LatLngBounds) => {
        if (!zoom || zoom <= 14) {
            setBeaconsInArea([]);
            setConstructionSitesInArea([]);
            return;
        }

        const beacons = await getBeaconsInArea(boundsToArea(bounds));
        if (!beacons?.length) {
            setBeaconsInArea([]);
            setConstructionSitesInArea([]);
            return;
        }
        setBeaconsInArea(beacons);

        var sites = await Promise.all(groupBy(beacons.filter(b => b.constructionSiteId && b.constructionProjectId), b => b.constructionSiteId).map(async site => {
            const cs = await getConstructionProjectSite({
                constructionProjectId: site.items[0].constructionProjectId!,
                constructionSiteId: site.key!
            });

            return {
                ...cs,
                label: site.items[0].constructionProjectName + ' / ' + cs.name
            };
        }));

        setConstructionSitesInArea(sites.filter(cs => cs.polygon).map(cs => ({
            color: 'orange',
            ring: cs.polygon!,
            title: cs.label,
        })));

    }, [setBeaconsInArea, setConstructionSitesInArea]);

    const onDraggedOrZoomed = useCallback((zoom: number, bounds: LatLngBounds) => {
        loadBeacons(zoom, bounds);
    }, [loadBeacons]);

    const onMapReady = useCallback((map: LeafletMap) => {
        setMap(map);
        if (props.onMapReady) {
            props.onMapReady(map);
        }
        loadBeacons(map.getZoom(), map.getBounds());
    }, [loadBeacons, setMap, props.onMapReady]);

    const preventDefault = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
    }, []);

    return <Maximizable buttonPosition="bottom-right">
        <div onClick={preventDefault} style={{ height: '100%', width: '100%' }}>
            <Map
                markers={(beaconsInArea || []).map(b => ({
                    lat: b.position.value.pos.lat,
                    lon: b.position.value.pos.lon,
                    title: b.constructionProjectId ? `${b.constructionProjectName} / ${b.serial || b.id}` : t('construction-project:beacon-label-not-assigned', { idOrSerial: b.serial || b.id }),
                    color: !b.constructionProjectId ? 'grey' : props.constructionProjectId === b.constructionProjectId ? 'green' : 'blue' 
                }))}
                polygons={constructionSitesInArea}
                allowNoMarkers
                onUserDraggedOrZoomed={onDraggedOrZoomed}
                onMapReady={onMapReady}
                defaultPosition={defaultPosition}
                controls={map ? <PolygonEditControl map={map} area={area} onAreaChanged={setArea} /> : undefined} />
            <DrawingInstruction title={t('construction-project:area-drawing-tooltip')} />
        </div>
    </Maximizable>;
}