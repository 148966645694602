import { Button, Modal, Spin } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingOutlined } from '@ant-design/icons';
import { Beacon, GetBeaconConfigurationResponse, PutBeaconConfigurationRequest, useConfigureBeacon } from '../../api';
import { BeaconConfigurationForm } from './BeaconConfigurationForm';
import { AuthContext } from '../../contexts/authContext';

interface BeaconConfigurationModalProps {
    beacon?: Beacon;
}

function hasDifferences(c1: PutBeaconConfigurationRequest | undefined, c2: GetBeaconConfigurationResponse | undefined) {
    return c1?.activityIntervalPeriod !== c2?.activityIntervalPeriod ||
        c1?.motionDetectionThreshold !== c2?.motionDetectionThreshold ||
        c1?.correctionServiceConfiguration.endpoint !== c2?.correctionServiceConfiguration.endpoint ||
        c1?.correctionServiceConfiguration.port !== c2?.correctionServiceConfiguration.port ||
        c1?.ntripConfiguration?.mountpoint !== c2?.ntripConfiguration?.mountpoint ||
        c1?.ntripConfiguration?.username !== c2?.ntripConfiguration?.username ||
        c1?.ntripConfiguration?.password !== c2?.ntripConfiguration?.password ||
        c1?.ntripConfiguration?.ntripRequestInterval !== c2?.ntripConfiguration?.ntripRequestInterval ||
        c1?.pointPerfectConfiguration?.correctionTopic !== c2?.pointPerfectConfiguration?.correctionTopic ||
        c1?.pointPerfectConfiguration?.distributionTopic !== c2?.pointPerfectConfiguration?.distributionTopic ||
        c1?.correctionServiceType !== c2?.correctionServiceType;
}

function hasAllRequiredFields(c: PutBeaconConfigurationRequest | undefined) {

    if (!c?.activityIntervalPeriod || !c?.motionDetectionThreshold) {
        return false;
    }
    
    if (!c?.correctionServiceConfiguration.endpoint || !c?.correctionServiceConfiguration.port) {
        return false;
    }

    const hasNtrip = !!c.ntripConfiguration?.mountpoint &&
        !!c.ntripConfiguration?.password &&
        !!c.ntripConfiguration?.username &&
        !!c.ntripConfiguration?.ntripRequestInterval;

    const hasPointPerfect = !!c.pointPerfectConfiguration?.correctionTopic && !!c.pointPerfectConfiguration.distributionTopic;

    return hasNtrip || hasPointPerfect;
}

export function BeaconConfigurationModal(props: BeaconConfigurationModalProps) {
    const { t } = useTranslation();
    const { beacon } = props;
    const [reloadToken, setReloadToken] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedConfiguration, setEditedConfiguration] = useState<{ edited: PutBeaconConfigurationRequest, original: GetBeaconConfigurationResponse }>();
    const onConfigurationEdited = useCallback((edited: PutBeaconConfigurationRequest, original: GetBeaconConfigurationResponse) => {
        setEditedConfiguration({ edited, original });
    }, [setEditedConfiguration]);
    const configureBeacon = useConfigureBeacon();

    const open = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        setIsOpen(true);
    }, [setIsOpen]);

    const authContext = useContext(AuthContext);
    const currentUserIsSolutionOperator = authContext.currentUser?.accessToken?.hasRole('solution-operator');

    const onCancel = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isSaving) {
            return;
        }
        setIsEditing(false);
        setEditedConfiguration(undefined);
        setIsOpen(false);
        setReloadToken(reloadToken + 1);
    }

    const onSave = async (e: React.MouseEvent) => {
        e.preventDefault();
        if (isSaving) {
            return;
        }
        setIsSaving(true);

        try {
            await configureBeacon({ beaconId: beacon!.id, configuration: editedConfiguration!.edited });
            setReloadToken(reloadToken + 1);
            setIsEditing(false);
            setEditedConfiguration(undefined);
        }
        finally {
            setIsSaving(false);
        }
    }

    const onEdit = (e: React.MouseEvent) => {
        e.preventDefault();
        if (isEditing) {
            return;
        }
        setIsEditing(true);
    }

    const isEditedConfigurationValid = hasAllRequiredFields(editedConfiguration?.edited) &&
        hasDifferences(editedConfiguration?.edited, editedConfiguration?.original);

    const modalFooter = [
        <Button key="cancel" disabled={isSaving} onClick={onCancel}>{t('cancel')}</Button>
    ];

    if (currentUserIsSolutionOperator) {
        if (isEditing) {
            modalFooter.push(<Button key="save" disabled={isSaving || !isEditedConfigurationValid}
                loading={isSaving}
                onClick={onSave}
                type="primary">
                {t('save')}
            </Button>);
        } else {
            modalFooter.push(<Button key="edit"
                onClick={onEdit}
                type="primary">
                {t('beacon:configuration-edit')}
            </Button>);
        }
    }

    return <>
        <Button className="settings-button" onClick={open} icon={<SettingOutlined />}></Button>
        <Modal width='60%' title={beacon ? t('beacon:configuration-title', { serial_or_id: beacon.serial || beacon.id }) : t('beacon:configuration')} visible={isOpen} onCancel={onCancel} onOk={onSave} footer={modalFooter}>
            <div onClick={e => e.preventDefault()}>
                {
                    beacon
                        ? <BeaconConfigurationForm
                            beacon={beacon}
                            reloadToken={reloadToken}
                            mode={isEditing ? 'edit' : 'readonly'}
                            editedConfiguration={editedConfiguration?.edited}
                            onConfigurationEdited={onConfigurationEdited} />
                        : <Spin />
                }
            </div>
        </Modal>
    </>;
}
