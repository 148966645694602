import { Button, Collapse, Drawer, Modal, Tabs } from 'antd';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BeaconWithPosition } from '../api';
import { AllBeaconsTable, PreloadedAllBeaconsTable } from './BeaconTable';
import { Collapsible } from './Collapsible';
import { MapPosition } from './Map';
import { SelectBeaconsOnMap } from './SelectBeaconsOnMap';

interface SelectBeaconsModalProps {
    modalButton?: (openModal: () => void) => React.ReactNode;
    modalButtonIcon?: React.ReactNode;
    title: string;
    instruction: string;
    selectButtonText(beaconIds: string[]): string;
    selectButtonTitle(beaconIds: string[]): string;
    assignedToOrganization?: boolean;
    onBeaconsSelected(beaconIds: string[]): Promise<any>;
    beacons?: BeaconWithPosition[];
    defaultMapPosition?: MapPosition;
}

export function SelectBeaconsModal(props: SelectBeaconsModalProps) {

    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const [selectedBeaconIds, setSelectedBeaconIds] = useState<string[]>([]);

    const openModal = useCallback(() => { setIsOpen(true) }, [setIsOpen]);

    const onSelect = async () => {
        setIsSelecting(true);
        try {
            await props.onBeaconsSelected(selectedBeaconIds);
        } catch {
            setIsSelecting(false);
            return;
        }
        setIsOpen(false);
        setIsSelecting(false);
        setSelectedBeaconIds([]);
    }

    const onCancel = () => {
        if (isSelecting) {
            return;
        }
        setIsOpen(false);
        setIsSelecting(false);
        setSelectedBeaconIds([]);
    }

    const modalFooter = [
        <Button key="cancel" disabled={isSelecting} onClick={onCancel}>{t('cancel')}</Button>,
        <Button key="select" disabled={isSelecting || !selectedBeaconIds.length}
            loading={isSelecting}
            onClick={onSelect}
            title={props.selectButtonTitle(selectedBeaconIds)}
            type="primary">
            {props.selectButtonText(selectedBeaconIds)}
        </Button>
    ];

    return <>
        {props.modalButton ? props.modalButton(openModal) : <Button onClick={openModal} type="primary">{props.modalButtonIcon}{props.title}</Button>}
        <Modal width="75%" style={{ top: Math.max(16, window.innerHeight * 0.15 - 77) }} bodyStyle={{ overflowY: 'auto' }} title={props.title} visible={isOpen} onCancel={onCancel} onOk={onSelect} footer={modalFooter}>
            <div style={{ height: window.innerHeight * 0.70 }}>
                <Collapsible
                    stateKey="select-beacons-modal-collapsible"
                    expanderTitle={t('beacon:select-modal-table')}
                    left={props.beacons
                        ? <PreloadedAllBeaconsTable hideFooter beacons={props.beacons}  {...{ selectedBeaconIds, setSelectedBeaconIds }} />
                        : <AllBeaconsTable
                            hideFooter
                            assignedToOrganization={props.assignedToOrganization}
                            {...{ selectedBeaconIds, setSelectedBeaconIds }} />}
                    right={<SelectBeaconsOnMap beacons={props.beacons} defaultMapPosition={props.defaultMapPosition} {...{ selectedBeaconIds, setSelectedBeaconIds }} instruction={props.instruction} />} />
            </div>
        </Modal>
    </>;
}