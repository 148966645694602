import { Alert } from "antd";

export function DrawingInstruction(props: { title: string }) {
    return <Alert
        className="button-like-icon-size"
        style={{ whiteSpace: 'nowrap', position: 'absolute', right: '50%', transform: 'translateX(50%)', top: '11px', height: '32px', zIndex: 999 }}
        message={props.title}
        type="info"
        showIcon
    />;
}