import { AmplifyLoadingSpinner } from "@aws-amplify/ui-react";
import { Button, Checkbox, Dropdown, Input, Menu, Spin, Table, Tag } from "antd";
import { ColumnType } from "antd/lib/table";
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBeacons, useOrganizationBeacons } from "../api";
import { Beacon, BeaconSorting, ConstructionSite, ErrorSeverity } from "../api/models";
import { ApiState } from "../api/useApiState";
import { groupBy, toDictionary, useDebouncedFilter, useFilterValueChangedHandler, useTransformedState } from "../util";
import { Badge } from "./Badge";
import { BeaconEnableDisableButton } from "./BeaconEnableDisableButton";
import { AuthContext } from '../contexts/authContext';
import { BeaconConfigurationModal } from "./beacon-configuration/BeaconConfigurationModal";
import { CopyableText } from "./copyable-text";
import { TFunction } from "i18next";
import { useEffect } from 'react';
import { SorterResult, TableLocale } from "antd/lib/table/interface";
import { AssignBeaconsModal } from "../screens/construction-project/AssignBeaconsModal";
import { MapPosition } from "./Map";
import { ConstructionSiteActionMenu } from "./ConstructionSiteActionMenu";
import { BeaconStatus } from "./BeaconStatus";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ActionsIcon } from "./ActionsIcon";
import { BeaconsErrors } from "./BeaconsErrors";
import { BeaconDrawerContext } from "./beacon-drawer/BeaconDrawerContext";
import { useHistory } from 'react-router-dom';
import { FilterValue, TableCurrentDataSource } from 'antd/es/table/interface';
import './BeaconTable.css';
import { getGlobalShowCanvasAllBeacons, setGlobalShowCanvasAllBeacons, subscribeToGlobalShowCanvasAllBeacons } from "./globalState";
 
interface BeaconTableProps {
    size?: 'small' | 'middle' | 'large';
    selectedBeaconIds?: string[];
    setSelectedBeaconIds?(ids: string[]): void;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    hideFooter?: boolean;
    additionalColumns?: ColumnType<Beacon>[];
    showConfigureButtons?: boolean;
    noData?: React.ReactNode | (() => React.ReactNode);
    loading?: boolean;
    onSort?(property: keyof (Beacon), direction: 'descend' | 'ascend' | undefined | null): void;
    hideIccid?: boolean;
    beacons: ApiState<Beacon[] | undefined>;
}
 
const SORT_ORDER_ASCEND = 'ascend';
const SORT_ORDER_DESCEND = 'descend';

export function BeaconTableCanvasView(props: BeaconTableProps) {
    const { selectedBeaconIds, setSelectedBeaconIds } = props;
    const { t } = useTranslation();
 
    const authContext = useContext(AuthContext);
    const currentUserIsSolutionOperator = !!authContext.currentUser?.accessToken?.hasRole('solution-operator');

    const [sortedBeacons, setSortedBeacons] = useState<Beacon[]>([]);
    const beaconArray = props.beacons.value || [];

    const beaconTableColumns = (
        t: TFunction,
        currentUserIsSolutionOperator: boolean,
        props: BeaconTableProps,
        actions: ((b: Beacon) => JSX.Element)[]
    ) => [
        {
        title: t('beacon:serial'),
        render: (b: Beacon) => b.serial || b.id,
        sorter: (a: Beacon, b: Beacon) => a.serial.localeCompare(b.serial),
        },
        {
            title: t('beacon:status'),
            render: (b: Beacon) => <BeaconStatus beacon={b} />,
            sorter: (a: Beacon, b: Beacon) => {
                const onA = a.environment?.value.batt.v || '';
                const onB = b.environment?.value.batt.v || '';
                if (onA < onB) {
                    return -1;
                }
                if (onA > onB) {
                    return 1;
                }
                return 0;
            },
        },
        ...(currentUserIsSolutionOperator && !props.hideIccid ? [{
            title: t('beacon:iccid'),
            render: (b: Beacon) => <CopyableText text={b.iccid} />,
            sorter: (a: Beacon, b: Beacon) => {
                const iccidA = a.iccid || '';
                const iccidB = b.iccid || '';
                return iccidA.localeCompare(iccidB);
            }
        }] : []),
        ...(anyDisabled ? [{
            title: t('beacon:disabled-title'),
            render: (b: Beacon) => b.isDisabled ? <Tag color="red">{t('beacon:disabled')}</Tag> : <></>
        }] : []),
        ...(props.additionalColumns || []),
        ...(actions.length > 0 ? [{
            title: t('actions'),
            render: (b: Beacon) => <Dropdown trigger={['click']} overlay={<Menu className="actions-menu">
                {actions.map((a, i) => <Menu.Item key={i}>
                    {a(b)}
                </Menu.Item>)}
            </Menu>}>
                <Button><ActionsIcon /></Button>
            </Dropdown>
        }] : []),
    ];
    
    useEffect(() => {
        setSortedBeacons([...beaconArray]);
    }, [beaconArray]);
    
    let { hideFooter } = props;
    hideFooter = hideFooter || (!props.footer && !setSelectedBeaconIds);
 
    const beaconDrawer = useContext(BeaconDrawerContext);
 
    const onChangeRowSelection = useCallback((selectedRowKeys: React.Key[]) => {
        const isOnCurrentPage = sortedBeacons.reduce((is, b) => {
            is[b.id] = true;
            return is;
        }, {} as { [id: string]: boolean });
 
        const selectedBeaconIdsOnCurrentPage = selectedRowKeys.filter(r => isOnCurrentPage[r]) as string[];
 
        setSelectedBeaconIds!([
            ...selectedBeaconIds!.filter(b => !isOnCurrentPage[b]),
            ...selectedBeaconIdsOnCurrentPage
        ]);
    }, [setSelectedBeaconIds, selectedBeaconIds, sortedBeacons]);
 
    const onSelectAll = useCallback((e: CheckboxChangeEvent) => {
        if (!sortedBeacons || !setSelectedBeaconIds) {
            return;
        }
        if (!e.target.checked) {
            setSelectedBeaconIds([]);
        } else {
            setSelectedBeaconIds(sortedBeacons.map(b => b.id));
        }
    }, [setSelectedBeaconIds, sortedBeacons]);
 
    const anyDisabled = !!sortedBeacons?.some(b => b.isDisabled);
    const { currentUser } = useContext(AuthContext);
    const currentUserIsSO = currentUser?.accessToken?.hasRole('solution-operator');
    const currentUserIsRSM = currentUser?.accessToken?.hasRole('road-safety-manager');
 
    const history = useHistory();
    const onRow = useCallback((beacon: Beacon) => {
        return {
            onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                if (e.defaultPrevented) {
                    return;
                }
                e.preventDefault();
                history.push(`/beacons/${beacon.id}`);
            },
            style: {
                cursor: 'pointer'
            }
        };
    }, [beaconDrawer]);
 
    const refresh = props.beacons.refresh;
    const renderEnableDisableButton = useCallback((b: Beacon) => <BeaconEnableDisableButton beacon={b} onUpdated={refresh} />, [refresh]);
    const renderConfigurationButton = useCallback((b: Beacon) => <BeaconConfigurationModal beacon={b} />, [refresh]);
    const actions: ((b: Beacon) => JSX.Element)[] = [];
    if (props.showConfigureButtons) {
        if (currentUserIsSolutionOperator) {
            actions.push(renderEnableDisableButton);
            actions.push(renderConfigurationButton);
        }
    };
 
    const tableLocale: TableLocale | undefined = props.noData ? { emptyText: props.noData } : undefined;

    const userId = authContext.currentUser?.email || 'defaultUser'; // Fallback to a default user key if userId is not available
    
    // Initializing state from localStorage or using 15 as default
    const [visibleRows, setVisibleRows] = useState<number>(() => {
        const savedVisibleRows = localStorage.getItem(`${userId}_visibleRows`);
        return savedVisibleRows ? parseInt(savedVisibleRows, 10) : 15;
    });

    // Saving the visibleRows value to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem(`${userId}_visibleRows`, visibleRows.toString());
    }, [visibleRows, userId]);

    const handleSortingChange = (columnKey: keyof Beacon, order: typeof SORT_ORDER_ASCEND | typeof SORT_ORDER_DESCEND | undefined | null) => {
        const sortedData = [...beaconArray].sort((a, b) => {
            if (!a[columnKey] && !b[columnKey]) return 0;
            if (!a[columnKey]) return order === SORT_ORDER_ASCEND ? -1 : 1;
            if (!b[columnKey]) return order === SORT_ORDER_ASCEND ? 1 : -1;

            const aValue = a[columnKey];
            const bValue = b[columnKey];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return order === SORT_ORDER_ASCEND ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                return order === SORT_ORDER_ASCEND ? aValue - bValue : bValue - aValue;
            } else if (aValue instanceof Date && bValue instanceof Date) {
                return order === SORT_ORDER_ASCEND ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
            }
            return 0;
        });

        setSortedBeacons(sortedData);
    };
 
    const onTableChange = (
        _pagination: any,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Beacon> | SorterResult<Beacon>[],
        extra: TableCurrentDataSource<Beacon>
    ) => {
        let effectiveSorter: SorterResult<Beacon> | undefined;
        if (Array.isArray(sorter) && sorter.length > 0) {
            effectiveSorter = sorter[0];
        } else if (!Array.isArray(sorter)) {
            effectiveSorter = sorter;
        }
        if (effectiveSorter && effectiveSorter.columnKey && effectiveSorter.order) {
            const columnKeyAsString = effectiveSorter.columnKey.toString();
            handleSortingChange(columnKeyAsString as keyof Beacon, effectiveSorter.order);
        }
    };

    const handleLoadMore = () => {
        setVisibleRows(prev => Math.min(prev + 15, sortedBeacons.length));
    };

    const tableComponentAllBeacons = (
        <Table
            sticky
            className={props.header ? 'with-custom-title' : undefined}
            title={props.header ? () => <div className="table-header-background">{props.header}</div> : undefined}
            locale={{
                emptyText: props.loading || !sortedBeacons.length ? <Spin size="small" /> : 'No Data', // Showing spinner when loading or no data
            }}
            size={props.size || 'middle'}
            dataSource={sortedBeacons} // Passing the entire sorted dataset
            loading={props.loading}
            rowSelection={setSelectedBeaconIds ? {
                type: 'checkbox',
                onChange: onChangeRowSelection,
                selectedRowKeys: selectedBeaconIds,
                columnTitle: <Checkbox onChange={onSelectAll} checked={!!selectedBeaconIds?.length && selectedBeaconIds?.length === sortedBeacons?.length} />
            } : undefined}
            onRow={onRow}
            rowKey="id"
            columns={beaconTableColumns(t, currentUserIsSolutionOperator, props, actions)}
            footer={footer(hideFooter, selectedBeaconIds, setSelectedBeaconIds, t, props)}
            onChange={onTableChange}
            pagination={false} 
            rowClassName={(record, index) => index >= visibleRows ? 'hidden-row' : ''} 
            style={{ backgroundColor: '#fafafa' }}
        />
    );
    
    const [pageSize, setPageSize] = useState<number>(15);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        const savedPageSize = localStorage.getItem(`${userId}_beaconPageSize`);
        if (savedPageSize) {
            setPageSize(Number(savedPageSize));
        }
    }, [userId]);

    const handlePageSizeChange = (current: number, size: number) => {
        setPageSize(size);
        setCurrentPage(current); // Adjusting current page if needed
        localStorage.setItem(`${userId}_beaconPageSize`, size.toString()); // Saving page-size to localStorage with user-specific key
    };
    
    const [showCanvasAllBeacons, setShowCanvasAllBeacons] = useState<boolean>(getGlobalShowCanvasAllBeacons());

    // Subscribing to changes in the global state
    useEffect(() => {
        const unsubscribe = subscribeToGlobalShowCanvasAllBeacons(setShowCanvasAllBeacons);
        return () => unsubscribe();  // Unsubscribing on component unmount
    }, []);

    // Loading the user's preference from localStorage on component mount
    useEffect(() => {
        const savedPreference = localStorage.getItem(`${userId}_showCanvasAllBeacons`);
        if (savedPreference !== null) {
            setShowCanvasAllBeacons(JSON.parse(savedPreference));
        }
    }, [userId]);
    
    const tableComponentPaginatedBeacons = (
        <div style={{ overflow: 'hidden' }}>
        <Table
            sticky
            className={props.header ? 'with-custom-title' : undefined}
            title={props.header ? () => props.header : undefined}
            locale={{
                emptyText: props.loading || !sortedBeacons.length ? <Spin size="small" /> : 'No Data',
            }}
            size={props.size || 'middle'}
            dataSource={sortedBeacons || []}
            loading={props.loading}
            rowSelection={setSelectedBeaconIds ? {
                type: 'checkbox',
                onChange: onChangeRowSelection,
                selectedRowKeys: selectedBeaconIds,
                columnTitle: <Checkbox onChange={onSelectAll} checked={!!selectedBeaconIds?.length && selectedBeaconIds?.length === sortedBeacons?.length} />
            } : undefined}
            onRow={onRow}
            rowKey="id"
            columns={beaconTableColumns(t, currentUserIsSolutionOperator, props, actions)}
            footer={footer(hideFooter, selectedBeaconIds, setSelectedBeaconIds, t, props)}
            onChange={onTableChange}
            pagination={{
                current: currentPage, // Using the current page state
                pageSize: pageSize, // Fetching state
                showSizeChanger: true, // Showing the size changer dropdown
                pageSizeOptions: ['15', '30', '50', '100'],
                onChange: (page) => setCurrentPage(page), // Handling page change
                onShowSizeChange: handlePageSizeChange, // Handling page-size change and saving to localStorage
            }}
            style={{ overflow: 'hidden' }}
        />
        </div>
    );
    
    return (
        !!props.beacons.loading && !beaconArray ? (
            <AmplifyLoadingSpinner data-testid="loading-spinner" />
        ) : (
            <div data-testid="canvas-container" className="canvas-container">
                {showCanvasAllBeacons ? (
                <div data-testid="beacons-data">
                    {tableComponentAllBeacons}
                    {sortedBeacons.length > visibleRows && (
                        <Button 
                            onClick={handleLoadMore} 
                            style={{ display: 'block', margin: '10px auto' }}>
                            Load More
                        </Button>
                    )}
                </div>
                ) : (
                <div>
                    {tableComponentPaginatedBeacons}
                </div>
                )}
            </div>
            )
        );
}
 
export function BeaconTable(props: BeaconTableProps) {
    const { selectedBeaconIds, setSelectedBeaconIds } = props;
    const { t } = useTranslation();
 
    const authContext = useContext(AuthContext);
    const currentUserIsSolutionOperator = authContext.currentUser?.accessToken?.hasRole('solution-operator');
 
    const [sortedBeacons, setSortedBeacons] = useState<Beacon[]>([]);
    const beaconArray = props.beacons.value || [];
    
    useEffect(() => {
        setSortedBeacons([...beaconArray]);
    }, [beaconArray]);
    
    let { hideFooter } = props;
    hideFooter = hideFooter || (!props.footer && !setSelectedBeaconIds);
 
    const beaconDrawer = useContext(BeaconDrawerContext);
 
    const onChangeRowSelection = useCallback((selectedRowKeys: React.Key[]) => {
        const isOnCurrentPage = sortedBeacons.reduce((is, b) => {
            is[b.id] = true;
            return is;
        }, {} as { [id: string]: boolean });
 
        const selectedBeaconIdsOnCurrentPage = selectedRowKeys.filter(r => isOnCurrentPage[r]) as string[];
 
        setSelectedBeaconIds!([
            ...selectedBeaconIds!.filter(b => !isOnCurrentPage[b]),
            ...selectedBeaconIdsOnCurrentPage
        ]);
    }, [setSelectedBeaconIds, selectedBeaconIds, sortedBeacons]);
 
    const onSelectAll = useCallback((e: CheckboxChangeEvent) => {
        if (!sortedBeacons || !setSelectedBeaconIds) {
            return;
        }
 
        if (!e.target.checked) {
            setSelectedBeaconIds([]);
        } else {
            setSelectedBeaconIds(sortedBeacons.map(b => b.id));
        }
    }, [setSelectedBeaconIds, sortedBeacons]);
 
    const anyDisabled = !!sortedBeacons?.some(b => b.isDisabled);
    const { currentUser } = useContext(AuthContext);
    const currentUserIsSO = currentUser?.accessToken?.hasRole('solution-operator');
    const currentUserIsRSM = currentUser?.accessToken?.hasRole('road-safety-manager');
 
    const history = useHistory();
    const onRow = useCallback((beacon: Beacon) => {
        return {
            onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                if (e.defaultPrevented) {
                    return;
                }
                e.preventDefault();
                history.push(`/beacons/${beacon.id}`);
            },
            style: {
                cursor: 'pointer'
            }
        };
    }, [beaconDrawer]);
 
    const refresh = props.beacons.refresh;
    const renderEnableDisableButton = useCallback((b: Beacon) => <BeaconEnableDisableButton beacon={b} onUpdated={refresh} />, [refresh]);
    const renderConfigurationButton = useCallback((b: Beacon) => <BeaconConfigurationModal beacon={b} />, [refresh]);
    const actions: ((b: Beacon) => JSX.Element)[] = [];
    if (props.showConfigureButtons) {
        if (currentUserIsSolutionOperator) {
            actions.push(renderEnableDisableButton);
            actions.push(renderConfigurationButton);
        }
    };
 
    const tableLocale: TableLocale | undefined = props.noData ? { emptyText: props.noData } : undefined;
 
    const handleSortingChange = (columnKey: keyof Beacon, order: typeof SORT_ORDER_ASCEND | typeof SORT_ORDER_DESCEND | undefined | null) => {
        const sortedData = [...beaconArray].sort((a, b) => {
            if (!a[columnKey] && !b[columnKey]) return 0;
            if (!a[columnKey]) return order === SORT_ORDER_ASCEND ? -1 : 1;
            if (!b[columnKey]) return order === SORT_ORDER_ASCEND ? 1 : -1;
 
            const aValue = a[columnKey];
            const bValue = b[columnKey];
 
            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return order === SORT_ORDER_ASCEND ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                return order === SORT_ORDER_ASCEND ? aValue - bValue : bValue - aValue;
            } else if (aValue instanceof Date && bValue instanceof Date) {
                return order === SORT_ORDER_ASCEND ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
            }
            return 0;
        });
 
        setSortedBeacons(sortedData);
    };
 
    const onTableChange = (
        _pagination: any,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Beacon> | SorterResult<Beacon>[],
        extra: TableCurrentDataSource<Beacon>
    ) => {
        let effectiveSorter: SorterResult<Beacon> | undefined;
        if (Array.isArray(sorter) && sorter.length > 0) {
            effectiveSorter = sorter[0];
        } else if (!Array.isArray(sorter)) {
            effectiveSorter = sorter;
        }
        if (effectiveSorter && effectiveSorter.columnKey && effectiveSorter.order) {
            const columnKeyAsString = effectiveSorter.columnKey.toString();
            handleSortingChange(columnKeyAsString as keyof Beacon, effectiveSorter.order);
        }
    };
    
    return !!props.beacons.loading && !beaconArray
        ? <AmplifyLoadingSpinner />
        : <> 
        <Table
            sticky
            className={props.header ? 'with-custom-title' : undefined}
            title={props.header ? () => props.header : undefined}
            locale={tableLocale}
            size={props.size || 'middle'}
            dataSource={sortedBeacons || []}
            loading={props.loading}
            rowSelection={setSelectedBeaconIds ? {
                type: 'checkbox',
                onChange: onChangeRowSelection,
                selectedRowKeys: selectedBeaconIds,
                columnTitle: <Checkbox onChange={onSelectAll} checked={!!selectedBeaconIds?.length && selectedBeaconIds?.length === sortedBeacons?.length} />
            } : undefined}
            onRow={onRow}
            rowKey="id"
            columns={[
                {
                    title: t('beacon:serial'),
                    render: (b: Beacon) => b.serial || b.id,
                    sorter: (a: Beacon, b: Beacon) => a.serial.localeCompare(b.serial),
                },
                {
                    title: t('beacon:status'),
                    render: (b: Beacon) => <BeaconStatus beacon={b} />,
                    sorter: (a: Beacon, b: Beacon) => {
                        const onA = a.environment?.value.batt.v || '';
                        const onB = b.environment?.value.batt.v || '';
                        if (onA < onB) {
                            return -1;
                        }
                        if (onA > onB) {
                            return 1;
                        }
                        return 0;
                    },
                },
                ...(currentUserIsSolutionOperator && !props.hideIccid ? [{
                    title: t('beacon:iccid'),
                    render: (b: Beacon) => <CopyableText text={b.iccid} />,
                    sorter: (a: Beacon, b: Beacon) => {
                        const iccidA = a.iccid || '';
                        const iccidB = b.iccid || '';
                        return iccidA.localeCompare(iccidB);
                    }
                }] : []),
                ...(anyDisabled ? [{
                    title: t('beacon:disabled-title'),
                    render: (b: Beacon) => b.isDisabled ? <Tag color="red">{t('beacon:disabled')}</Tag> : <></>
                }] : []),
                ...(props.additionalColumns || []),
                ...(actions.length > 0 ? [{
                    title: t('actions'),
                    render: (b: Beacon) => <Dropdown trigger={['click']} overlay={<Menu className="actions-menu">
                        {actions.map((a, i) => <Menu.Item key={i}>
                            {a(b)}
                        </Menu.Item>)}
                    </Menu>}>
                        <Button><ActionsIcon /></Button>
                    </Dropdown>
                    }] : []),
                ]}
                footer={footer(hideFooter, selectedBeaconIds, setSelectedBeaconIds, t, props)}
                onChange={onTableChange}
                pagination={sortedBeacons.length > 10 ? { pageSize: 10 } : false} 
        />
    </>;
}

interface ConstructionProjectBeaconsTableProps {
    constructionProjectId: string;
    beacons: ApiState<Beacon[] | undefined>;
    sites: ApiState<ConstructionSite[] | undefined>;
    selectedBeaconIds?: string[];
    setSelectedBeaconIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
    defaultMapPositionForBeaconAssignments?: MapPosition;
    startEditingConstructionSite(constructionSite: ConstructionSite): void;
}
 
function footer(hideFooter: boolean, selectedBeaconIds: string[] | undefined, setSelectedBeaconIds: ((ids: string[]) => void) | undefined, t: TFunction, props: BeaconTableProps) {
    return hideFooter ? undefined : () => <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto auto 1fr', gridColumnGap: '1em' }}>
        {selectedBeaconIds && setSelectedBeaconIds && <Badge count={selectedBeaconIds.length} color="gray">
            <Button
                disabled={selectedBeaconIds.length === 0}
                onClick={() => setSelectedBeaconIds([])}>
                {t('construction-site:clear-selected-beacons')}
            </Button>
        </Badge>}
        {props.footer}
    </div>;
}
 
export function ConstructionProjectBeaconsTable(props: ConstructionProjectBeaconsTableProps) {
    const { t } = useTranslation();
    const isRoadSafetyManager = useContext(AuthContext).currentUser?.accessToken.hasRole('road-safety-manager');
 
    const refresh = useCallback(() => {
        props.beacons.refresh();
        props.sites.refresh();
    }, [props.beacons.refresh, props.sites.refresh]);
 
    type BeaconPerConstructionSite = {
        key: string | undefined,
        items: ApiState<Beacon[]>
    };
 
    const constructionSitesById = useTransformedState(props.sites.value, sites => toDictionary(sites ?? [], site => site.id));
 
    const beaconsByConstructionSite: BeaconPerConstructionSite[] = useTransformedState(props.beacons.value,
        beacons =>
            groupBy(beacons ?? [], b => b.constructionSiteId).map(g => ({
                key: g.key, items: {
                    loading: false,
                    failed: false,
                    refresh: async () => { },
                    value: g.items
                }
            })).sort((c1, c2) => {
                if (!c1.key) {
                    return 1;
                }
                if (!c2.key) {
                    return -1;
                }
                try {
                    const c1CreatedAt = constructionSitesById.get(c1.key)!.createdAt;
                    const c2CreatedAt = constructionSitesById.get(c2.key)!.createdAt;
                    return c1CreatedAt > c2CreatedAt ? 1 : -1;
                } catch {
                    return 0;
                }
            }), constructionSitesById);
 
    const expandedRowRender = useCallback((row: BeaconPerConstructionSite) => {
        const { beacons, footer, hideFooter, ...rest } = props;
        const { selectedBeaconIds, setSelectedBeaconIds, ...restWithoutBeaconSelection } = rest;
        return <BeaconTable hideIccid size="small" hideFooter beacons={row.items} {...(isRoadSafetyManager ? rest : restWithoutBeaconSelection)} />;
    }, [props]);
 
    const hasNoBeacons = props.beacons.value !== undefined && props.beacons.value !== null && !props.beacons.value?.length;
 
    if (beaconsByConstructionSite.length === 0 || beaconsByConstructionSite.length === 1 && beaconsByConstructionSite[0].key === null) {
        const { selectedBeaconIds, setSelectedBeaconIds, ...propsWithoutBeaconSelection } = props;
        return <BeaconTable hideIccid loading={props.beacons.loading} hideFooter {...propsWithoutBeaconSelection}
            noData={isRoadSafetyManager && hasNoBeacons
                ? <AssignBeaconsModal constructionProjectId={props.constructionProjectId} defaultMapPosition={props.defaultMapPositionForBeaconAssignments} modalButton={onOpen => <div onClick={onOpen} style={{ padding: 20, cursor: 'pointer', display: 'inline-block' }}>
                    <PlusCircleOutlined className="primary-icon" style={{ fontSize: '3em' }} />
                    <div style={{ color: 'black', marginTop: '1em' }}>{t('construction-project:assign-beacons-long')}</div>
                </div>} />
                : <></>} />;
    }
 
    return <Table sticky columns={[
        {
            title: t('construction-site'),
            render: (item: BeaconPerConstructionSite) => item.key ? (constructionSitesById.get(item.key)?.name || <Spin size="small" />) : t('construction-site:not-assigned')
        },
        ...(isRoadSafetyManager ? [{
            title: t('actions'),
            width: '95px',
            render: (item: BeaconPerConstructionSite) => !!item.key && <ConstructionSiteActionMenu constructionProjectId={props.constructionProjectId} startEditing={() => props.startEditingConstructionSite(constructionSitesById.get(item.key!)!)} beacons={item.items.value} refresh={refresh} selectedBeaconIds={props.selectedBeaconIds} setSelectedBeaconIds={props.setSelectedBeaconIds} />
        }] : [])]}
        size="middle"
        loading={props.beacons.loading}
        dataSource={beaconsByConstructionSite}
        pagination={false}
        expandable={{ expandedRowRender }}>
    </Table>;
}
 
interface OrganizationBeaconsTableProps {
    organizationId: string;
    selectedBeaconIds?: string[];
    setSelectedBeaconIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
}
 
export function OrganizationBeaconsTable(props: OrganizationBeaconsTableProps) {
    const pagedBeacons = useOrganizationBeacons(props.organizationId);
 
    const beacons: ApiState<Beacon[] | undefined> = {
        ...pagedBeacons,
        value: pagedBeacons.value ? pagedBeacons.value.items : undefined
    };
 
    return <BeaconTable beacons={beacons} showConfigureButtons {...props} />;
}
 
interface AllBeaconsTableProps {
    assignedToOrganization?: boolean;
    selectedBeaconIds?: string[];
    showConfigureButtons?: boolean;
    setSelectedBeaconIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
    showFilter?: boolean;
    additionalColumns?: ColumnType<Beacon>[];
}
 
export function AllBeaconsTable(props: AllBeaconsTableProps) {
 
    const { showFilter, assignedToOrganization, additionalColumns, ...restProps } = props;
    const { filterValueForApi, filterValueForInput, onFilterValueChanged } = useDebouncedFilter();
    const [sorting, setSorting] = useState<BeaconSorting>();
    
    const onSortingChange = useCallback((property: keyof (Beacon), direction: typeof SORT_ORDER_DESCEND | typeof SORT_ORDER_ASCEND | null | undefined) => {
        if (property === 'lastMessageReceivedAt' && direction === SORT_ORDER_ASCEND) {
            setSorting(BeaconSorting.LastMessageReceivedAtAscending);
        } else if (property === 'lastMessageReceivedAt' && direction === SORT_ORDER_DESCEND) {
            setSorting(BeaconSorting.LastMessageReceivedAtDescending);
        }
        else {
            setSorting(undefined);
        }
    }, [setSorting]);
 
    const auth = useContext(AuthContext);
    const { t } = useTranslation();
    const pagedBeacons = useBeacons(assignedToOrganization, filterValueForApi, undefined, sorting);
 
    const beacons: ApiState<Beacon[] | undefined> = {
        value: pagedBeacons.value?.items,
        loading: pagedBeacons.loading,
        failed: pagedBeacons.failed,
        lastLoadedAt: pagedBeacons.lastLoadedAt,
        refresh: pagedBeacons.refresh,
    };
 
    const onFilterChanged = useFilterValueChangedHandler(pagedBeacons, onFilterValueChanged);
 
    const dataIndexLastMessageReceivedAt: keyof (Pick<Beacon, 'lastMessageReceivedAt'>) = 'lastMessageReceivedAt';
    
    return <BeaconTableCanvasView
        header={showFilter
            ? <div className="custom-title">
                <Input suffix={<SearchOutlined />} value={filterValueForInput} onChange={onFilterChanged} allowClear placeholder={t('beacon:filter-placeholder' + (auth.currentUser?.accessToken.hasRole('road-safety-manager') ? '-rsm' : ''))} style={{ width: '25em' }} />
                <BeaconsErrors severity={ErrorSeverity.Error} assignedToOrganization={assignedToOrganization} filter={filterValueForApi} style={{ marginLeft: '1em' }} />
                <BeaconsErrors severity={ErrorSeverity.Warning} assignedToOrganization={assignedToOrganization} filter={filterValueForApi} style={{ marginLeft: '1em' }} />
            </div>
            : undefined}
        beacons={beacons}
        additionalColumns={[
            ...(props.additionalColumns ?? []),
            {
                title: t('beacon:last-message-received-at'),
                render: (b: Beacon) => b.lastMessageReceivedAt?.toLocaleString(),
                sorter: (a: Beacon, b: Beacon) => {
                    const dateA = a.lastMessageReceivedAt;
                    const dateB = b.lastMessageReceivedAt;
 
                    if (!dateA && !dateB) {
                        return 0; 
                    }
                    if (!dateA) {
                        return 1; 
                    }
                    if (!dateB) {
                        return -1; 
                    }
 
                    return dateA.getTime() - dateB.getTime(); 
                },
                sortDirections: [SORT_ORDER_DESCEND, SORT_ORDER_ASCEND, null],
                defaultSortOrder: null,
            }
        ]}
        {...restProps} />;
}
 
interface PreloadedAllBeaconsTableProps {
    beacons: Beacon[];
    selectedBeaconIds?: string[];
    showConfigureButtons?: boolean;
    setSelectedBeaconIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
}
 
export function PreloadedAllBeaconsTable(props: PreloadedAllBeaconsTableProps) {
    const { beacons, ...rest } = props;
    const beaconsState: ApiState<Beacon[] | undefined> = useTransformedState(props.beacons, b => ({
        loading: false,
        failed: false,
        refresh: async () => { },
        value: b
    }));
    return <BeaconTableCanvasView beacons={beaconsState} {...rest} />;
}