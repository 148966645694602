import { useCallback } from "react";

export function get<
    T,
    P1 extends keyof NonNullable<T>>(value: T, prop1: P1)
    : NonNullable<T>[P1]
export function get<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>>(value: T, prop1: P1, prop2: P2)
    : NonNullable<NonNullable<T>[P1]>[P2];
export function get<T>(value: T, ...properties: string[]) {
    let v = value as any;
    for (let i = 0; i < properties.length; i++) {
        v = v[properties[i]];
        if (v === null || v === undefined) {
            return v;
        }
    }
    return v;
}

export function useOnChangeListener<
    T,
    P1 extends keyof NonNullable<T>>(currentValue: T, listener: (newValue: T) => void, prop1: P1)
    : (value: NonNullable<T>[P1]) => void | undefined;
export function useOnChangeListener<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>>(currentValue: T, listener: (newValue: T) => void, prop1: P1, prop2: P2)
    : (value: NonNullable<NonNullable<T>[P1]>[P2]) => void | undefined;
export function useOnChangeListener<T>(currentValue: T, listener: (newValue: T) => void, ...properties: string[]) {
    return useCallback((value: any) => {
        const newValue = JSON.parse(JSON.stringify(currentValue));
        let changedObject = newValue as any;
        for (let i = 0; i < properties.length - 1; i++) {
            changedObject = changedObject[properties[i]];
        }
        changedObject[properties[properties.length - 1]] = value;
        listener(newValue);
    }, [currentValue, listener, ...properties]);
}

export function useOnChangeListenerDynamic<T, P1 extends keyof NonNullable<T>>(currentValue: T, listener: (newValue: T) => void, properties: keyof NonNullable<T> | [keyof NonNullable<T>, keyof NonNullable<NonNullable<T>[P1]>]) {

    const props = Array.isArray(properties) ? properties : [properties];

    return useCallback((value: any) => {
        const newValue = JSON.parse(JSON.stringify(currentValue));
        let changedObject = newValue as any;
        for (let i = 0; i < props.length - 1; i++) {
            changedObject = changedObject[props[i]];
        }
        changedObject[props[props.length - 1]] = value;
        listener(newValue);
    }, [currentValue, listener, ...props]);
}