import { Button } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type BeaconTelemetryFilterValue = 'ShowAll' | 'GpsOnly' | 'AccurateCorrectedPosition' | 'LatestOnly';

interface BeaconTelemetryFilterProps {
    style?: React.CSSProperties;
    filter: BeaconTelemetryFilterValue;
    onFilterChanged(newFilter: BeaconTelemetryFilterValue): void;
}

export function BeaconTelemetryFilter(props: BeaconTelemetryFilterProps) {

    const { filter, onFilterChanged } = props;
    const { t } = useTranslation();
    const showAccurate = useCallback(() => onFilterChanged('AccurateCorrectedPosition'), [onFilterChanged]);
    const showGpsOnly = useCallback(() => onFilterChanged('GpsOnly'), [onFilterChanged]);
    const showLatestOnly = useCallback(() => onFilterChanged('LatestOnly'), [onFilterChanged]);
    const showAll = useCallback(() => onFilterChanged('ShowAll'), [onFilterChanged]);

    return <div className="multi-button-container" style={props.style}>
        <Button size="small" onClick={showAll} type={filter === 'ShowAll' ? 'primary' : 'default'}>{t('beacon:telemetry-filter-show-all')}</Button>
        <Button size="small" onClick={showAccurate} type={filter === 'AccurateCorrectedPosition' ? 'primary' : 'default'}>{t('beacon:telemetry-filter-show-accurate-only')}</Button>
        <Button size="small" onClick={showGpsOnly} type={filter === 'GpsOnly' ? 'primary' : 'default'}>{t('beacon:telemetry-filter-show-gps-only')}</Button>
        <Button size="small" onClick={showLatestOnly} type={filter === 'LatestOnly' ? 'primary' : 'default'}>{t('beacon:telemetry-filter-show-latest-only')}</Button>
    </div>
}