import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { Maximizable } from "./Maximizable";
import { CirclePosition, Map, MapPolyline } from "./Map";
import { Beacon, BeaconActiveErrorResponse, BeaconPosition, ErrorSeverity } from "../api";

interface BeaconDetailMapProps {
    loading: boolean;
    lastLoadedAt?: Date;
    beacon: Beacon;
    position?: BeaconPosition;
    polyLines?: MapPolyline[];
    activeErrors?: BeaconActiveErrorResponse[];
}

export function BeaconDetailMap(props: BeaconDetailMapProps) {

    const { t } = useTranslation();
    const { loading, beacon, position, activeErrors } = props;
    
    const positionCircle: CirclePosition | undefined =
        (position && position?.value?.md?.hAcc > 100)
            ? {
                lat: position?.value.pos.lat!,
                lon: position?.value.pos.lon!,
                radiusInMeters: position!.value.md.hAcc / 1000
            }
            : undefined;

    if (loading && !position) {
        return <Spin />;
    }

    if (!position) {
        return <>
            {loading && <Spin className="beacon-detail-map-spin-no-position" />}
            <Alert message={t('no-position')} type='info' showIcon />
        </>
    }

    return <Maximizable buttonPosition='bottom-right'
        label={<span>{loading && <Spin className="beacon-detail-map-spin" />}</span>}>
        <Map markers={[{
            lat: position.value.pos.lat,
            lon: position.value.pos.lon,
            title: t('beacon:marker-title', { serial: beacon.serial || beacon.id, at: position.measuredAt.toLocaleString() }),
            errorSeverity: activeErrors?.some(e => e.severity === ErrorSeverity.Error) ? 'error' : 'warning',
        }]}
        circle={positionCircle}
        polyLines={props.polyLines} />
    </Maximizable>;
}