import React from 'react';
import { useTranslation } from 'react-i18next';
import { BeaconEnvironment } from '../api';
import { Button } from 'antd';
import { CsvColumn, useDownloadCsv } from '../util';

interface DownloadEnvironmentMessagesProps {
    style?: React.CSSProperties;
    beaconId: string;
    environmentMessages: BeaconEnvironment[];
    from: Date;
    to: Date;
}

const columns: CsvColumn<BeaconEnvironment>[] = [
    {
        title: 'BeaconId',
        value: (_p, _c, i) => i
    },
    {
        title: 'MeasuredAt',
        value: (p, c) => p.measuredAt.toLocaleString(c)
    },
    {
        title: 'Batt',
        value: (p, c) => p.value.batt.v.toLocaleString(c)
    },
    {
        title: 'IccId',
        value: (p, c) => p.value.cell.iccid
    }
];

export function DownloadEnvironmentMessages(props: DownloadEnvironmentMessagesProps) {
    const { t } = useTranslation();
    const { environmentMessages, from, to, beaconId } = props;
    const download = useDownloadCsv(beaconId, environmentMessages, columns, `EnvironmentMessages_${beaconId}_${from.toISOString()}_${to.toISOString()}.csv`);

    return <div style={props.style}>
        <Button 
            type="dashed" 
            className="download-positions-button"
            onClick={download}>{t('save-as-csv')}
        </Button>
    </div>;
}