import { useTranslation } from "react-i18next";
import { Beacon } from "../../api";
import { AllBeaconsTable } from "../../components/BeaconTable";

export function Beacons() {
    const { t } = useTranslation();
    return <AllBeaconsTable showConfigureButtons showFilter additionalColumns={[{
        title: t('construction-project:name'),
        render: (b: Beacon) => !!b.constructionProjectName ? `${b.constructionProjectName}${b.constructionProjectCreatedAutomatically ? (` (${t('construction-project:created-automatically')})`) : ''}` : '',
        sorter: (a: Beacon, b: Beacon) => {
            const siteNameA = a.constructionProjectName || '';
            const siteNameB = b.constructionProjectName || '';
            if (siteNameA < siteNameB){
                return -1;
            }
            if (siteNameA > siteNameB) {
                return 1;
            }
            return 0;
        }
    }]} />
}