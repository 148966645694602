import { useCallback } from "react";
import { GeolocationResponse } from "./models";
import { ApiCall, useAuthorizedFetch } from "./useApiState";

export function useGetLocationOf(): ApiCall<string, GeolocationResponse> {
    const authorizedFetch = useAuthorizedFetch();
    return useCallback(async (address: string) => {
        const result = await authorizedFetch('geocoding/location-of/' + address, { method: 'get' });
        if (!result.ok) {
            throw result.status;
        }
        var location = await result.json() as GeolocationResponse;
        return location;
    }, [authorizedFetch]);
}